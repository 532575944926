<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <full-page-loader :loading="fullLoading"></full-page-loader>
  <div class="page-wrapper">
    <div class="content">
      <product-header></product-header>

      <div class="card table-list-card">
        <div class="card-body">
          <div class="table-top">
            <div class="search-set">
              <div class="search-input">
                <input
                  type="text"
                  v-model="searchInput"
                  placeholder="Search"
                  class="dark-input"
                />
                <a href="javascript:void(0);" class="btn btn-searchset">
                  <i data-feather="search" class="feather-search"></i>
                </a>
              </div>
            </div>
            <!-- <div class="search-path">
              <a
                class="btn btn-filter"
                id="filter_search"
                v-on:click="filter = !filter"
                :class="{ setclose: filter }"
              >
                <vue-feather type="filter" class="filter-icon"></vue-feather>
                <span
                  ><img src="@/assets/img/icons/closes.svg" alt="img"
                /></span>
              </a>
            </div> -->
            <!-- <div class="form-sort">
              <vue-feather type="sliders" class="info-img"></vue-feather>
              <vue-select
                :options="Sortby"
                id="sortby"
                placeholder="Sort by Date"
              />
            </div> -->
          </div>
          <!-- /Filter -->
          <div
            class="card mb-0"
            :style="{ display: filter ? 'block' : 'none' }"
            id="filter_inputs"
          >
            <div class="card-body pb-0">
              <div class="row">
                <div class="col-lg-12 col-sm-12">
                  <div class="row">
                    <div class="col-lg-2 col-sm-6 col-12">
                      <div class="input-blocks">
                        <vue-feather type="box" class="info-img"></vue-feather>
                        <vue-select
                          :options="ChooseFilter"
                          id="choosefilter"
                          placeholder="Choose Product"
                        />
                      </div>
                    </div>
                    <div class="col-lg-2 col-sm-6 col-12">
                      <div class="input-blocks">
                        <vue-feather
                          type="stop-circle"
                          class="info-img"
                        ></vue-feather>
                        <vue-select
                          :options="CategroyFilter"
                          id="categroyfilter"
                          placeholder="Choose Categroy"
                        />
                      </div>
                    </div>

                    <div class="col-lg-2 col-sm-6 col-12">
                      <div class="input-blocks">
                        <vue-feather
                          type="git-merge"
                          class="info-img"
                        ></vue-feather>
                        <vue-select
                          :options="CategroySubFilter"
                          id="categroysubfilter"
                          placeholder="Choose Sub Categroy"
                        />
                      </div>
                    </div>

                    <div class="col-lg-2 col-sm-6 col-12">
                      <div class="input-blocks">
                        <vue-feather
                          type="stop-circle"
                          class="info-img"
                        ></vue-feather>
                        <vue-select
                          :options="BrandFilter"
                          id="brandfilter"
                          placeholder="All Brand"
                        />
                      </div>
                    </div>

                    <div class="col-lg-2 col-sm-6 col-12">
                      <div class="input-blocks">
                        <i class="fas fa-money-bill info-img"></i>
                        <vue-select
                          :options="PriceFilter"
                          id="Pricefilter"
                          placeholder="Price"
                        />
                      </div>
                    </div>
                    <div class="col-lg-2 col-sm-6 col-12">
                      <div class="input-blocks">
                        <a class="btn btn-filters ms-auto">
                          <i data-feather="search" class="feather-search"></i>
                          Search
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Filter -->
          <div class="table-responsive product-list">
            <div
              class="spinner-container d-flex justify-content-center align-items-center"
              v-if="loading"
            >
              <div class="spinner-grow text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>

            <a-table
              v-else
              class="table datanew table-hover table-center mb-0"
              :columns="columns"
              :data-source="filteredProducts"
              :locale="{ emptyText: emptyText }"
              :pagination="pagination"
            >
              <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'Product'">
                  <div class="productimgname">
                    <a href="javascript:void(0);" class="product-img stock-img">
                      <img
                        :src="
                          record.image ||
                          require(`@/assets/img/products/default-product-image.png`)
                        "
                        alt="product"
                      />
                    </a>
                    <div class="d-flex flex-column gap-2">
                      <a href="javascript:void(0);">{{ record.product_name }} </a>
                      <a href="javascript:void(0);">{{ record.reference_id }} </a>
                    </div>
                  </div>
                </template>

                <template v-else-if="column.key === 'CreatedBy'">
                  <td class="userimgname">
                    <a href="javascript:void(0);" class="product-img me-2">
                      <img
                        :src="require(`@/assets/img/users/${record.UserImage}`)"
                        alt="product"
                      />
                    </a>
                    <a href="javascript:void(0);">{{ record.reatedby }}</a>
                  </td>
                </template>
                <template v-else-if="column.key === 'action'">
                  <td class="action-table-data">
                    <div class="edit-delete-action">
                      <router-link
                        class="me-2 edit-icon p-2"
                        :to="`/inventory/products/${record.id}/product-details`"
                      >
                        <i data-feather="eye" class="feather-eye"></i>
                      </router-link>
                      <router-link
                        class="me-2 p-2"
                        :to="`/inventory/products/${record.id}/edit-product`"
                      >
                        <i data-feather="edit" class="feather-edit"></i>
                      </router-link>
                      <a
                        class="confirm-text p-2"
                        @click="delProduct(record.id, record.product_name)"
                        href="javascript:void(0);"
                      >
                        <vue-feather
                          type="trash-2"
                          class="trash-2"
                        ></vue-feather>
                      </a>
                    </div>
                  </td>
                </template>
              </template>
            </a-table>
          </div>
        </div>
      </div>
      <!-- /product list -->
    </div>
  </div>
  <product-list-modal></product-list-modal>
</template>
<script>
import Swal from "sweetalert2";
import { fetchStoreProducts, deleteProduct } from "@/api/products";
import { selectedStoreState } from "@/state/storeState";
import { openLoginModal } from "@/assets/js/utils";

const columns = [
  {
    title: "Product",
    dataIndex: "product_name",
    key: "Product",
    sorter: {
      compare: (a, b) => {
        a = a.product_name.toLowerCase();
        b = b.product_name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  // {
  //   title: "SKU",
  //   dataIndex: "SKU",
  //   sorter: {
  //     compare: (a, b) => {
  //       a = a.SKU.toLowerCase();
  //       b = b.SKU.toLowerCase();
  //       return a > b ? -1 : b > a ? 1 : 0;
  //     },
  //   },
  // },
  {
    title: "Category",
    dataIndex: ["category", "category_name"],
    sorter: {
      compare: (a, b) => {
        a = a.category.category_name.toLowerCase();
        b = b.category.category_name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Brand",
    dataIndex: ["brand", "name"],
    sorter: {
      compare: (a, b) => {
        a = a.brand.name.toLowerCase();
        b = b.brand.name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Price",
    dataIndex: "selling_price",
    sorter: {
      compare: (a, b) => {
        a = parseFloat(a.selling_price);
        b = parseFloat(b.selling_price);
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  // {
  //   title: "Unit",
  //   dataIndex: "Unit",
  //   sorter: {
  //     compare: (a, b) => {
  //       a = a.Unit.toLowerCase();
  //       b = b.Unit.toLowerCase();
  //       return a > b ? -1 : b > a ? 1 : 0;
  //     },
  //   },
  // },
  {
    title: "Qty",
    dataIndex: "quantity_in_stock",
    sorter: {
      compare: (a, b) => {
        a = a.quantity_in_stock;
        b = b.quantity_in_stock;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  // {
  //   title: "Created by",
  //   dataIndex: "CreatedBy",
  //   key: "CreatedBy",
  //   sorter: {
  //     compare: (a, b) => {
  //       a = a.CreatedBy.toLowerCase();
  //       b = b.CreatedBy.toLowerCase();
  //       return a > b ? -1 : b > a ? 1 : 0;
  //     },
  //   },
  // },
  {
    title: "Action",
    key: "action",
    // sorter: true,
  },
];

export default {
  data() {
    return {
      filter: false,
      Sortby: ["Sort by Date", "14 09 23", "11 09 23"],
      ChooseFilter: ["Choose Product", "Lenovo 3rd Generation", "Nike Jordan"],
      CategroyFilter: ["Laptop", "Shoe"],
      CategroySubFilter: ["Choose Sub Categroy", "Computers", "Fruits"],
      BrandFilter: ["Lenovo", "Nike"],
      PriceFilter: ["Price", "$12500.00", "$12500.00"],
      loading: true, // Initial loading state
      data: [],
      filteredProducts: [],
      columns,
      emptyText: "",
      fullLoading: false,
      searchInput: "",
      pagination: {
        pageSize: 25, // Number of items per page
        // showSizeChanger: true, // Optional: allows the user to change page size
        pageSizeOptions: ["25", "50", "100"], // Optional: options for page size
      },
    };
  },
  created() {
    this.fetchProducts(selectedStoreState.id);
  },
  methods: {
    async fetchProducts(storeId) {
      try {
        const products = await fetchStoreProducts(storeId);
        this.data = products;
        this.filteredProducts = [...this.data];
      } catch (error) {
        this.data = [];
        

        this.emptyText = "Error: " + error.response.data.message;
        Swal.fire({
          title: "Unable To Fetch Products",
          text: error.response.data.message,
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        });
      } finally {
        this.loading = false; // Hide loader after fetching data
      }
    },
    delProduct(productId, productName) {
      Swal.fire({
        title: `Are you sure you want to delete <strong>${productName}</strong>?`,
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonColor: "#3085d6",
        confirmButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        confirmButtonClass: "btn btn-danger",
        cancelButtonClass: "btn btn-primary ml-1",
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          try {
            // Make request to delete product
            this.fullLoading = true;

            await deleteProduct(selectedStoreState.id, productId);

            // update products
            await this.fetchProducts(selectedStoreState.id);

            this.fullLoading = false;

            Swal.fire({
              icon: "success",
              title: "Deleted!",
              text: "Product deleted successfully.",
              confirmButtonClass: "btn btn-success",
            });
          } catch (error) {
          } finally {
          }
        }
      });
    },
  },
  watch: {
    searchInput(searchTerm) {
      // Filter the products based on the search term
      this.filteredProducts = this.data.filter((product) =>
        product.product_name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    },
  },
};
</script>
