import axios from "axios";

// Import the auth state
import { authState } from "@/state/authState";
import { openLoginModal } from "@/assets/js/utils";
import Swal from "sweetalert2";

const axiosJSONInstance = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    timeout: 10000,
    headers: { "Content-Type": "application/json" },
});

// Add a request interceptor
axiosJSONInstance.interceptors.request.use(
    config => {
        // Get the access token from the auth state
        const token = authState.accessToken;
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        // Handle the error
        return Promise.reject(error);
    }
);

axiosJSONInstance.interceptors.response.use(
    response => {
        // If the response is successful, just return the response data
        return response;
    },
    error => {
        // Handle token expiration error globally
        if (
            error.response &&
            error.response.data.message === "token_not_valid"
        ) {
            openLoginModal();
            return;
        }
        else if (error.request) {
            Swal.fire({
                title: "An error occurred",
                text: error.message,
                icon: "error",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
            });
            return Promise.reject(error);
        }
        else {
            // Handle other errors globally (optional)
            return Promise.reject(error);
        }
    }
);

export default axiosJSONInstance;
