<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <div class="page-wrapper">
    <div class="content">
      <div class="page-header mb-5">
        <div class="add-item d-flex">
          <div class="page-title">
            <h3>Store Selection</h3>
            <h6>Select A Store to Manage Products, Sales, and Staff</h6>
          </div>
        </div>
        <ul class="table-top-head">
          <li>
            <a data-bs-toggle="tooltip" data-bs-placement="top" title="Refresh"
              ><i data-feather="rotate-ccw" class="feather-rotate-ccw"></i
            ></a>
          </li>
          <li>
            <a
              ref="collapseHeader"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Collapse"
              @click="toggleCollapse"
            >
              <i data-feather="chevron-up" class="feather-chevron-up"></i>
            </a>
          </li>
        </ul>
        <div class="page-btn">
          <a
            href="javascript:void(0);"
            class="btn btn-added"
            data-bs-toggle="modal"
            data-bs-target="#add-stores"
          >
            <vue-feather type="plus-circle" class="me-2"></vue-feather>
            Create Store
          </a>
        </div>
      </div>

      <div class="employee-grid-widget">
        <div class="row justify-content-center">
          <div
            class="spinner-container d-flex justify-content-center align-items-center"
            v-if="loading"
          >
            <div class="spinner-grow text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <div
            v-else-if="!loading && errorMessage"
            class="col-md-12 text-center"
          >
            <h5>{{ errorMessage }}</h5>
          </div>
          <div
            v-else-if="!loading && stores.length == 0"
            class="col-md-12 text-center h-75 d-flex flex-column align-items-center gap-5"
          >
            <h5>You have not created any stores yet</h5>
            <a
              href="javascript:void(0);"
              class="btn btn-added"
              data-bs-toggle="modal"
              data-bs-target="#add-stores"
              ><vue-feather type="plus-circle" class="me-2"></vue-feather>Add
              New Store</a
            >
          </div>
          <div
            v-else-if="!loading && stores.length > 0"
            class="col-md-6"
            @click="toNextPage(store.id)"
            v-for="store in stores"
            :key="store.id"
          >
            <div class="employee-grid-profile d-flex flex-column">
              <div class="profile-head">
                <div class="dep-name d-flex align-items-center">
                  <vue-feather type="home"></vue-feather>
                  <h5>{{ store.store_name }} - {{ store.branch_name }}</h5>
                </div>
              </div>
              <div
                class="profile-info department-profile-info align-items-center"
              >
                <div class="profile-pic mb-0">
                  <img
                    :src="
                      store.logo
                        ? store.logo
                        : require('@/assets/img/store/default-store-logo.png')
                    "
                    alt=""
                  />
                </div>
              </div>
              <ul class="team-members">
                <li>Products:</li>
                <li>Brands:</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->

  <!-- <div class="container-fluid">
          <div class="row custom-pagination">
            <div class="col-md-12">
              <div class="paginations d-flex justify-content-end mb-3">
                <span><i class="fas fa-chevron-left"></i></span>
                <ul class="d-flex align-items-center page-wrap">
                  <li>
                    <a href="javascript:void(0);" class="active"> 1 </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);"> 2 </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);"> 3 </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);"> 4 </a>
                  </li>
                </ul>
                <span><i class="fas fa-chevron-right"></i></span>
              </div>
            </div>
          </div>
        </div> -->
  <!-- </div>
  </div> -->
  <add-store-modal @store-modal-closed="handleStoreAdded"></add-store-modal>
</template>
<script>
import { router } from "@/router";
import { fetchStores } from "@/api/stores";
import { updateSelectedStoreState } from "@/state/storeState";
import { openLoginModal } from "@/assets/js/utils";
import { authState } from "@/state/authState";

export default {
  data() {
    return {
      SortDepa: ["Sort by Date", "Newest", "Oldest"],
      Choosedepa: ["Choose Department", "UI/UX", "HR", "Admin", "Engineering"],
      ChooseHOD: ["Choose HOD", "Mitchum Daniel", "Susan Lopez"],
      stores: [],
      loading: true,
      errorMessage: "",
    };
  },
  created() {
    this.getStores();
  },
  methods: {
    handleStoreAdded() {
      this.getStores();
    },
    async getStores() {
      this.loading = true; // Show loader before fetching data

      // API call to get admin stores
      try {
        const stores = await fetchStores();
        this.stores = stores;
      } catch (error) {
        this.stores = [];
        this.errorMessage = "Error: " + error.response.data.message;

        

        Swal.fire({
          title: "Unable To Fetch Stores",
          text: error.response.data.message,
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        });
      } finally {
        this.loading = false; // Hide loader after fetching data
      }
    },
    toNextPage(storeId) {
      // find a store with matching id from the list of objects of stores
      const store = this.stores.find((store) => store.id === storeId);

      if (store) {
        // save store to state
        updateSelectedStoreState(store);

        if (authState.user.role == "ADMIN") {
          router.push(`/dashboard/admin-dashboard`);
        } else if (authState.user.role == "STAFF") {
          router.push(`/sales/pos`);
        }
      }
    },
    toggleCollapse() {
      const collapseHeader = this.$refs.collapseHeader;

      if (collapseHeader) {
        collapseHeader.classList.toggle("active");
        document.body.classList.toggle("header-collapse");
      }
    },
    toggleCollapse() {
      const collapseHeader = this.$refs.collapseHeader;

      if (collapseHeader) {
        collapseHeader.classList.toggle("active");
        document.body.classList.toggle("header-collapse");
      }
    },
  },
};
</script>

<style scoped>
.btn-added {
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  background: var(--color-primary);
  padding: 8px 11px;
  color: #ffffff;
  font-weight: 700;
  font-size: 14px;
  border-radius: 8px;
  width: fit-content;
}

.btn-added:hover {
  color: #ffffff;
  background: #1b2850;
}

.employee-grid-profile {
  transition: all 0.3s ease;
}

.employee-grid-profile:hover {
  cursor: pointer;
  box-shadow: 0px 0px 10px 0px #ff9f4361;
  border: 1px solid transparent;
}

.employee-grid-profile .profile-info .profile-pic img {
  height: 200px;
  max-width: unset;
}

.employee-grid-profile .profile-info.department-profile-info .profile-pic {
  width: unset;
  height: unset;
}

.employee-grid-profile:hover .profile-head,
.employee-grid-profile:hover h5 {
  color: var(--color-primary);
}
</style>
