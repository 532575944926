import { openLoginModal } from "@/assets/js/utils";
import { authState } from "@/state/authState";
import axios from "axios";

const axiosFormDataInstance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 10000,
  headers: { "Content-Type": "multipart/form-data" },
});

// Add a request interceptor
axiosFormDataInstance.interceptors.request.use(
  (config) => {
    // Get the access token from the auth state
    const token = authState.accessToken;
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    // Handle the error
    return Promise.reject(error);
  }
);

axiosFormDataInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.data.message == "token_not_valid") {
      openLoginModaldal();
      return;
    } else {
      return Promise.reject(error);
    }
  }
)

export default axiosFormDataInstance;
