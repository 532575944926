import axiosJSONInstance from "./axiosJSONInstance";

// get dashboard data
export const fetchDashboardData = async (storeId) => {
    try {
        const response = await axiosJSONInstance.get(`/api/v1/stores/${storeId}/dashboard/`);

        return response.data.data;
    } catch (error) {
        console.error("Error fetching dashboard data", error);
        throw error;
    }
};