import axiosJSONInstance from "./axiosJSONInstance";

// api call to make record a payment for a credit sale
export const makeCreditSalePayment = async (saleId, amount) => {
    try {
      const response = await axiosJSONInstance.post(
        `/api/v1/stores/sale/${saleId}/salemakepayments/`,
        amount
      );
  
      return response.data.data;
    } catch (error) {
      console.error("Error making payment for credit sale", error);
      throw error;
    }
  };
  