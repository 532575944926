<template>
    <!-- Make Credit Sale Payment -->
    <div class="modal fade" id="credit-sale-payment" ref="credit-sale-payment">
        <div class="modal-dialog modal-dialog-centered custom-modal-two">
            <div class="modal-content">
                <div class="page-wrapper-new p-0">
                    <div class="content">
                        <div class="modal-header border-0 custom-modal-header justify-content-between">
                            <div class="page-title">
                                <h4>Record Payment for Credit Sale</h4>
                                <h5>{{ saleDetail.reference_id }}</h5>
                            </div>
                            <button type="button" id="close-credit-sale-payment" class="close" data-bs-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body custom-modal-body">
                            <div class="d-flex flex-column gap-2">
                                <p class="m-0 p-0"><span class="fw-bold text-secondary">Customer:</span> {{
                                    saleDetail?.customer_profile?.first_name + ' ' +
                                    saleDetail?.customer_profile?.last_name }}</p>
                                <p class="m-0 p-0"><span class="fw-bold text-secondary">Total Bill: </span>GH₵ {{
                                    saleDetail?.total_bill?.toFixed(2) }}</p>
                                <p class="m-0 p-0"><span class="fw-bold text-secondary">Already Paid: </span>GH₵ {{
                                    saleDetail?.total_paid }}</p>
                                <p class="m-0 p-0"><span class="fw-bold text-secondary">Balance: </span>GH₵ {{
                                    saleDetail?.remaining_balance?.toFixed(2) }}</p>
                            </div>
                            <div class="mt-3">
                                <label class="form-label">Amount</label>
                                <input type="text" v-model="paymentAmount" class="form-control" />
                            </div>
                            <div class="modal-footer-btn">
                                <a href="javascript:void(0);" class="btn btn-cancel me-2"
                                    data-bs-dismiss="modal">Cancel</a>
                                <a href="javascript: void(0);" @click="makePayment" class="btn btn-submit">Record
                                    Payment</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- /Make Credit Sale Payment -->
    <full-page-loader :loading=fullLoading />

</template>

<script>
import { makeCreditSalePayment } from '@/api/sales';


export default {
    props: {
        saleDetail: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            paymentAmount: 0,
            fullLoading: false,
        }
    },
    methods: {
        makePayment() {
            this.fullLoading = true;

            // Make API call to record the payment
            const jsonData = JSON.stringify({ amount: this.paymentAmount })
            makeCreditSalePayment(this.saleDetail.id, jsonData).then((data) => {

                // show alert message
                Swal.fire({
                    title: "Payment Successful",
                    text: "Payment recorded successfully",
                    icon: "success",
                });

                // clear the input
                this.paymentAmount = 0;

                // Update the sale detail with the new payment details
                this.$emit('credit-payment-success');
                document.querySelector('#close-credit-sale-payment').click();

            }).catch((error) => {
                Swal.fire({
                    title: "Error",
                    text: error?.response?.data?.message || error.message,
                    icon: "error",
                });
            }).finally(() => { this.fullLoading = false; });

        }
    },
    watch: {
        paymentAmount(value) {
            // ensure that the value never exceeds the saleDetail remaining balance
            this.paymentAmount = Math.min(value, this.saleDetail.remaining_balance);
        },
    }
}

</script>