<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <full-page-loader :loading="fullLoading"></full-page-loader>
  <div class="page-wrapper">
    <div class="content">
      <div class="page-header">
        <div class="add-item d-flex">
          <div class="page-title">
            <h4>Store List</h4>
            <h6>Manage your Stores</h6>
          </div>
        </div>
        <ul class="table-top-head">
          <!-- <li>
            <a data-bs-toggle="tooltip" data-bs-placement="top" title="Pdf"
              ><img src="@/assets/img/icons/pdf.svg" alt="img"
            /></a>
          </li> -->
          <!-- <li>
            <a data-bs-toggle="tooltip" data-bs-placement="top" title="Excel"
              ><img src="@/assets/img/icons/excel.svg" alt="img"
            /></a>
          </li> -->
          <!-- <li>
            <a data-bs-toggle="tooltip" data-bs-placement="top" title="Print"
              ><i data-feather="printer" class="feather-printer"></i
            ></a>
          </li> -->
          <li>
            <a
              @click="getStores"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Refresh"
              ><i data-feather="rotate-ccw" class="feather-rotate-ccw"></i
            ></a>
          </li>
          <li>
            <a
              ref="collapseHeader"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Collapse"
              @click="toggleCollapse"
            >
              <i data-feather="chevron-up" class="feather-chevron-up"></i>
            </a>
          </li>
        </ul>
        <div class="page-btn">
          <a
            href="javascript:void(0);"
            class="btn btn-added"
            data-bs-toggle="modal"
            data-bs-target="#add-stores"
            @click="resetEditModalProps()"
            ><vue-feather type="plus-circle" class="me-2"></vue-feather> Create
            New Store</a
          >
        </div>
      </div>
      <!-- /product list -->
      <div class="card table-list-card">
        <div class="card-body">
          <div class="table-top">
            <div class="search-set">
              <div class="search-input">
                <input type="text" placeholder="Search" v-model="searchInput" class="dark-input" />
                <a href="" class="btn btn-searchset"
                  ><i data-feather="search" class="feather-search"></i
                ></a>
              </div>
            </div>
            <!-- <div class="search-path">
              <a
                class="btn btn-filter"
                id="filter_search"
                v-on:click="filter = !filter"
                :class="{ setclose: filter }"
              >
                <vue-feather type="filter" class="filter-icon"></vue-feather>
                <span><img src="@/assets/img/icons/closes.svg" alt="img" /></span>
              </a>
            </div> -->
            <!-- <div class="form-sort">
              <vue-feather type="sliders" class="info-img"></vue-feather>
              <vue-select
                :options="StoreSort"
                id="storesort"
                placeholder="Sort by Date"
              />
            </div> -->
          </div>
          <!-- /Filter -->
          <!-- <div
            class="card"
            :style="{ display: filter ? 'block' : 'none' }"
            id="filter_inputs"
          >
            <div class="card-body pb-0">
              <div class="row">
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="input-blocks">
                    <vue-feather type="zap" class="info-img"></vue-feather>
                    <vue-select :options="Store" id="store" placeholder="Choose Stores" />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="input-blocks">
                    <vue-feather type="calendar" class="info-img"></vue-feather>
                    <div class="input-groupicon">
                      <date-picker
                        v-model="startdate"
                        placeholder="Choose Date"
                        class="form-control"
                        :editable="true"
                        :clearable="false"
                        :input-format="dateFormat"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="input-blocks">
                    <vue-feather type="stop-circle" class="info-img"></vue-feather>
                    <vue-select
                      :options="StoreStatus"
                      id="storestatus"
                      placeholder="Choose Status"
                    />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12 ms-auto">
                  <div class="input-blocks">
                    <a class="btn btn-filters ms-auto">
                      <i data-feather="search" class="feather-search"></i> Search
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <!-- /Filter -->
          <div class="table-responsive">
            <div
              class="spinner-container d-flex justify-content-center align-items-center"
              v-if="loading"
            >
              <div class="spinner-grow text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <a-table
              v-else
              class="table datanew"
              :columns="columns"
              :data-source="filteredData"
            >
              <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'Status'">
                  <div>
                    <span class="badge badge-linesuccess">{{
                      record.Status
                    }}</span>
                  </div>
                </template>
                <template v-if="column.key === 'action'">
                  <td class="action-table-data">
                    <div class="edit-delete-action">
                      <a
                        class="me-2 p-2"
                        href="javascript:void(0);"
                        @click="populateEditStoreModal(record.id)"
                      >
                        <i data-feather="edit" class="feather-edit"></i>
                      </a>
                      <a
                        class="confirm-text p-2"
                        @click="delStore(record.id, record.full_name)"
                        href="javascript:void(0);"
                      >
                        <i data-feather="trash-2" class="feather-trash-2"></i>
                      </a>
                    </div>
                  </td>
                </template>
              </template>
            </a-table>
          </div>
        </div>
      </div>
      <!-- /product list -->
    </div>
  </div>
  <add-store-modal
    :isEditMode="isEditingAStore"
    :editStoreData="toEditStoreData"
    @store-modal-closed="handleStoreModalClosed"
  ></add-store-modal>
</template>
<script>
const columns = [
  {
    title: "Store Name",
    dataIndex: "full_name",
    sorter: {
      compare: (a, b) => {
        a = a.full_name.toLowerCase();
        b = b.full_name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Location",
    dataIndex: "location",
    sorter: {
      compare: (a, b) => {
        a = a.location.toLowerCase();
        b = b.location.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Phone",
    dataIndex: "contact",
    sorter: {
      compare: (a, b) => {
        a = a.contact.toLowerCase();
        b = b.contact.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    sorter: {
      compare: (a, b) => {
        a = a.email.toLowerCase();
        b = b.email.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  // {
  //   title: "Status",
  //   dataIndex: "Status",
  //   key: "Status",
  //   sorter: {
  //     compare: (a, b) => {
  //       a = a.Status.toLowerCase();
  //       b = b.Status.toLowerCase();
  //       return a > b ? -1 : b > a ? 1 : 0;
  //     },
  //   },
  // },
  {
    title: "Action",
    key: "action",
    sorter: true,
  },
];

import Swal from "sweetalert2";
import { deleteStore, fetchAdminStoreDetails, fetchStores } from "@/api/stores";
import bootstrapBundleMin from "bootstrap/dist/js/bootstrap.bundle.min";

export default {
  data() {
    return {
      filter: false,
      StoreSort: ["Sort by Date", "Newest", "Oldest"],
      StoreStatus: ["Choose Status", "Active", "Inactive"],
      Store: ["Choose Store", "Thomas", "Rasmussen", "Benjamin"],
      data: [],
      filteredData: [],
      columns,
      loading: true,
      fullLoading: false,
      emptyText: "",
      isEditingAStore: false, // flag to determine if we are editing a store or adding a new one
      toEditStoreData: {
        id: "",
        store_name: "",
        branch_name: "",
        email: "",
        contact: "",
        location: "",
        logo: null,
      }, // data of the store currently being edited
      searchInput: "",
    };
  },
  created() {
    this.getStores();
  },
  methods: {
    populateEditStoreModal(storeId) {
      this.isEditingAStore = true;
      this.fullLoading = true;
      fetchAdminStoreDetails(storeId)
        .then((data) => {
          // Update the edit store data
          this.toEditStoreData = data; // This will automatically trigger the watch in the modal component

          const modalElement = document.getElementById("add-stores");
          const modal = new bootstrapBundleMin.Modal(modalElement);
          modal.show();
        })
        .catch((error) => {
          Swal.fire({
            title: "Error",
            text: error.response?.data?.message || error.message,
            icon: "error",
          });
        })
        .finally(() => {
          this.fullLoading = false;
        })
  },
    async getStores() {
      fetchStores().then(data => {
        this.data = data;
      }).catch(error => {
        this.data = [];

        this.emptyText = "Error: " + error.response.data.message;
        Swal.fire({
          title: "Unable To Fetch Products",
          text: error.response?.data?.message || error.message,
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        });
    }).finally(() => {
      this.loading=false;
      this.filteredData=this.data;
    })
    },

    handleStoreModalClosed() {
      this.getStores();
      this.resetEditModalProps();
    },
    resetEditModalProps() {
      // reset add modal props
      this.isEditingAStore = false;
      this.toEditStoreData = {
        id: "",
        store_name: "",
        branch_name: "",
        email: "",
        contact: "",
        location: "",
        logo: null,
      };
    },
    delStore(storeId, storeName) {
      Swal.fire({
        title: `Are you sure you want to delete <strong>${storeName}</strong>?`,
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonColor: "#3085d6",
        confirmButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        confirmButtonClass: "btn btn-danger",
        cancelButtonClass: "btn btn-primary ml-1",
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          try {
            // Make request to delete store
            this.loading = true;

            await deleteStore(storeId);

            // update stores
            await this.getStores();

            this.loading = false;

            Swal.fire({
              icon: "success",
              title: "Deleted!",
              text: "Store deleted successfully.",
              confirmButtonClass: "btn btn-success",
            });
          } catch (error) {
          } finally {
          }
        }
      });
    },
    toggleCollapse() {
      const collapseHeader = this.$refs.collapseHeader;

      if (collapseHeader) {
        collapseHeader.classList.toggle("active");
        document.body.classList.toggle("header-collapse");
      }
    },
  },
  watch: {
    searchInput(searchTerm) {
      this.filteredData = this.data.filter((store) =>
        (store.store_name + " - " + store.branch_name).toLocaleLowerCase().includes(searchTerm.toLowerCase())
      );
    }
  }
};
</script>
