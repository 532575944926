import axiosJSONInstance from "./axiosJSONInstance";

// create customer API Call 
export const createCustomer = async (storeId, data) => {
    try {
        const response = await axiosJSONInstance.post(
            `/api/v1/auth/store/${storeId}/customers/`,
            data
        );

        return response.data.data;
    } catch (error) {
        console.error("Error creating customer", error);
        throw error;
    }
};

// fetch customers
export const fetchCustomers = async (storeId) => {
    try {
        const response = await axiosJSONInstance.get(`/api/v1/auth/store/${storeId}/customers/`);
        return response.data.data;
    } catch (error) {
        console.error("Error fetching customers", error);
        throw error;
    }
};
