<template>
  <!-- Print Invoice -->
  <div class="modal fade modal-default print-receipt-invoice" id="print-invoice" aria-labelledby="print-invoice">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="d-flex justify-content-end">
          <button type="button" ref="closeInvoiceModalBtn" class="close p-0 m-2" data-bs-dismiss="modal"
            aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <div ref="invoiceToPrint" id="invoice-print-area">
            <div class="icon-head text-center">
              <a href="javascript:;">
                <img src="@/assets/img/pos-logo.svg" width="100" height="30" alt="Invoice Logo">
              </a>
            </div>
            <div class="text-center info text-center">
              <h6>{{ store?.full_name }}</h6>
              <p class="mb-0">Phone Number: {{ store?.contact }}</p>
              <p class="mb-0">Email: {{ store?.email }}</p>
            </div>
            <div class="tax-invoice">
              <h6 class="text-center">Purchase Invoice</h6>
              <div class="row">
                <div class="col-12">
                  <div class="invoice-user-name"><span>Sale REF:
                    </span><span>{{ saleData?.reference_id }}</span></div>
                </div>
                <div class="col-12">
                  <div class="invoice-user-name"><span>Customer: </span><span>{{
                    saleData?.customer_name
                      }}</span></div>
                </div>
                <div class="col-12">
                  <div class="invoice-user-name"><span>Sales Person: </span><span>{{
                    salesPersonName
                      }}</span></div>
                </div>
                <div class="col-sm-12 col-md-8">
                  <div class="invoice-user-name"><span>Issue Date: </span><span>{{ currentDate }}</span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-4">
                  <div class="invoice-user-name"><span>Time: </span><span>{{ currentTime }}</span>
                  </div>
                </div>
                <div class="col-12">
                  <div class="invoice-user-name"><span>Due Date: </span><span>{{
                    invoiceDueDate
                      }}</span></div>
                </div>
              </div>
            </div>
            <table class="table-borderless w-100 table-fit">
              <thead>
                <tr>
                  <th># Item</th>
                  <th>Price</th>
                  <th>Qty</th>
                  <th class="text-end">Total</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(item, index) in saleData?.sale_items" :key="index">
                  <td>{{ index + 1 }}. {{ item.product_name }}</td>
                  <td>GH₵{{ item.unit_price }}</td>
                  <td>{{ item.quantity }}</td>
                  <td class="text-end">
                    GH₵{{ (item.unit_price * item.quantity).toFixed(2) }}
                  </td>
                </tr>
                <tr>
                  <td colspan="4">
                    <table class="table-borderless w-100 table-fit">
                      <tr>
                        <td>Sub Total :</td>
                        <td class="text-end">
                          GH₵{{ saleData?.sub_total?.toFixed(2) }}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Discount
                          {{
                            saleData?.discount_type?.toUpperCase() ==
                              "PERCENTAGE"
                              ? `(${saleData?.discount_value}%)`
                              : ""
                          }}
                          :
                        </td>
                        <td class="text-end">
                          GH₵{{ saleData?.calculated_discount?.toFixed(2) }}
                        </td>
                      </tr>
                      <tr>
                        <td>Initial Payment :</td>
                        <td class="text-end">
                          GH₵{{ saleData?.total_paid?.toFixed(2) }}
                        </td>
                      </tr>
                      <tr>
                        <td>Total Due :</td>
                        <td class="text-end">
                          GH₵{{ saleData?.total_bill?.toFixed(2) }}
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="text-center invoice-bar">
              <!-- <a href="javascript:;">
                                <img src="@/assets/img/barcode/barcode-03.jpg" alt="Barcode">
                            </a>
                            <p>Sale 31</p> -->
              <p>Thank You For Shopping With Us. Please Come Again</p>
            </div>
          </div>
          <a href="javascript:void(0);" @click="sendStuffToTheSaleCreateRoute" class="btn btn-primary w-100">Complete
            Order & Print Invoice</a>
        </div>
      </div>
    </div>
  </div>
  <!-- /Print Invoice -->

  <full-page-loader :loading="fullLoading"></full-page-loader>
</template>

<script>
import { createSale } from '@/api/fetchSaleDetail';
import { getCurrentDateFormatted, getCurrentTimeFormatted, formatDate, formatTime } from '@/assets/js/utils';
import { authState } from '@/state/authState';
import { selectedStoreState } from '@/state/storeState';


export default {
  props: {
    saleData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      store: null,
      currentDate: "",
      currentTime: "",
      fullLoading: false,
      salesPersonName: "",
      invoiceDueDate: null,
    };
  },
  created() {
    this.store = selectedStoreState;
    this.salesPersonName = authState.userProfile?.first_name;
  },
  mounted() {
    const printInvoiceModal = document.getElementById('print-invoice');
    printInvoiceModal.addEventListener('shown.bs.modal', () => {
      this.currentDate = getCurrentDateFormatted();
      this.currentTime = getCurrentTimeFormatted();
      this.invoiceDueDate = formatTime(this.saleData?.payment_due_date);
    });
  },
  methods: {
    sendStuffToTheSaleCreateRoute() {
      this.fullLoading = true;
      // remove the fields the backend isn't expecting
      const { total_bill, sub_total, calculated_discount, ...data } = this.saleData;

      createSale(selectedStoreState.id, JSON.stringify(data)).then(sale => {

        // emit an event to show sale has been created successfully
        this.$emit('sale-created', sale);

        this.$refs.closeInvoiceModalBtn.click();

        // Show success alert
        Swal.fire({
          title: "Sale Complete",
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        });

        this.printInvoice();

      })
        .catch((error) => {
          Swal.fire({
            title: "Error Creating Sale",
            text: error?.response?.data?.message || error.message,
            icon: "error",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
          });
        })
        .finally(() => {
          this.fullLoading = false;
        });
    },
    printInvoice() {
      // Get HTML to print from element
      const prtHtml = this.$refs.invoiceToPrint.outerHTML;

      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Add print-specific styles
      stylesHtml += `
    <style>
        @page {
            size: 80mm auto; /* Set the paper width to 80mm, height will be auto */
            margin: 0; /* Set the margins to 0 */
        }
        * {
            font-size: 12px !important; /* Adjust the font size as needed */
        }
        body {
            margin: 0;
            padding: 0;
            width: 80mm; /* Ensure the body width matches the paper size */
        }
        #invoice-print-area {
            width: 100%; /* Ensure the invoice content takes up the full width */
            display: flex;
            flex-direction: column;
            gap: 16px;
            padding: 10px;
        }
        #invoice-print-area table thead th {
            color: #092C4C;
            width: auto;
            min-width: auto;
            padding: 5px;
            border-top: 1px dashed #5B6670;
            border-bottom: 1px dashed #5B6670;
        }
        #invoice-print-area table tbody tr table {
            border-top: 1px dashed #5B6670;
            border-bottom: 1px dashed #5B6670;
        }
        #invoice-print-area .tax-invoice {
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
        #invoice-print-area .tax-invoice h6:after,
        #invoice-print-area .tax-invoice h6:before {
            transform: translateY(-50%);
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            content: "";
            border-top: 1px dashed #5B6670;
            border-top-width: 1px;
            border-top-style: dashed;
            border-top-color: rgb(91, 102, 112);
            width: 30%;
        }
    </style>`;

      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
                            <html>
                            <head>
                                ${stylesHtml}
                            </head>
                            <body>
                                ${prtHtml}
                            </body>
                            </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      // WinPrint.close();
    },
  },
};
</script>
