<template>
  <!-- Payment Completed -->
  <div
    class="modal fade modal-default"
    id="payment-completed"
    aria-labelledby="payment-completed"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body text-center">
          <form @submit.prevent="submitForm">
            <div class="icon-head">
              <a href="javascript:;">
                <vue-feather
                  type="check-circle"
                  class="feather-40"
                ></vue-feather>
              </a>
            </div>
            <h4>Payment Completed</h4>
            <p class="mb-0">
              Do you want to Print Receipt for the Completed Order
            </p>
            <div class="modal-footer d-sm-flex justify-content-between">
              <button
                type="button"
                class="btn btn-primary flex-fill"
                data-bs-toggle="modal"
                data-bs-target=".print-receipt-invoice"
              >
                Print Receipt<i
                  class="feather-arrow-right-circle icon-me-5"
                ></i>
              </button>
              <button type="submit" class="btn btn-secondary flex-fill">
                Next Order<i class="feather-arrow-right-circle icon-me-5"></i>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Payment Completed -->

  <!-- Products -->
  <div
    class="modal fade modal-default pos-modal"
    id="products"
    aria-labelledby="products"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div
          class="modal-header p-4 d-flex align-items-center justify-content-between"
        >
          <div class="d-flex align-items-center">
            <h5 class="me-4">Products</h5>
            <span class="badge bg-info d-inline-block mb-0"
              >Order ID : #666614</span
            >
          </div>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body p-4">
          <form @submit.prevent="submitForm">
            <div class="product-wrap">
              <div
                class="product-list d-flex align-items-center justify-content-between"
              >
                <div class="d-flex align-items-center flex-fill">
                  <a href="javascript:void(0);" class="img-bg me-2">
                    <img
                      src="@/assets/img/products/pos-product-16.png"
                      alt="Products"
                    />
                  </a>
                  <div
                    class="info d-flex align-items-center justify-content-between flex-fill"
                  >
                    <div>
                      <span>PT0005</span>
                      <h6><a href="javascript:void(0);">Red Nike Laser</a></h6>
                    </div>
                    <p>GH₵2000</p>
                  </div>
                </div>
              </div>
              <div
                class="product-list d-flex align-items-center justify-content-between"
              >
                <div class="d-flex align-items-center flex-fill">
                  <a href="javascript:void(0);" class="img-bg me-2">
                    <img
                      src="@/assets/img/products/pos-product-17.png"
                      alt="Products"
                    />
                  </a>
                  <div
                    class="info d-flex align-items-center justify-content-between flex-fill"
                  >
                    <div>
                      <span>PT0235</span>
                      <h6><a href="javascript:void(0);">Iphone 14</a></h6>
                    </div>
                    <p>GH₵3000</p>
                  </div>
                </div>
              </div>
              <div
                class="product-list d-flex align-items-center justify-content-between"
              >
                <div class="d-flex align-items-center flex-fill">
                  <a href="javascript:void(0);" class="img-bg me-2">
                    <img
                      src="@/assets/img/products/pos-product-16.png"
                      alt="Products"
                    />
                  </a>
                  <div
                    class="info d-flex align-items-center justify-content-between flex-fill"
                  >
                    <div>
                      <span>PT0005</span>
                      <h6><a href="javascript:void(0);">Red Nike Laser</a></h6>
                    </div>
                    <p>GH₵2000</p>
                  </div>
                </div>
              </div>
              <div
                class="product-list d-flex align-items-center justify-content-between"
              >
                <div class="d-flex align-items-center flex-fill">
                  <a href="javascript:void(0);" class="img-bg me-2">
                    <img
                      src="@/assets/img/products/pos-product-17.png"
                      alt="Products"
                    />
                  </a>
                  <div
                    class="info d-flex align-items-center justify-content-between flex-fill"
                  >
                    <div>
                      <span>PT0005</span>
                      <h6><a href="javascript:void(0);">Red Nike Laser</a></h6>
                    </div>
                    <p>GH₵2000</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer d-sm-flex justify-content-end">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button type="submit" class="btn btn-primary">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Products -->

  <div
    class="modal fade"
    id="create"
    tabindex="-1"
    aria-labelledby="create"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Create New Customer</h5>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <div class="row">
              <div class="col-lg-6 col-sm-12 col-12">
                <div class="input-blocks">
                  <label>First Name</label>
                  <input
                    v-model="newCustomer.first_name"
                    type="text"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="col-lg-6 col-sm-12 col-12">
                <div class="input-blocks">
                  <label>Last Name</label>
                  <input
                    v-model="newCustomer.last_name"
                    type="text"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="col-lg-6 col-sm-12 col-12">
                <div class="input-blocks">
                  <label>Email</label>
                  <input
                    v-model="newCustomer.email_address"
                    type="email"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="col-lg-6 col-sm-12 col-12">
                <div class="input-blocks">
                  <label>Phone</label>
                  <input
                    v-model="newCustomer.phone_contact"
                    type="text"
                    class="form-control"
                  />
                </div>
              </div>
              <!-- <div class="col-lg-6 col-sm-12 col-12">
                                <div class="input-blocks">
                                    <label>Country</label>
                                    <input type="text" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-12 col-12">
                                <div class="input-blocks">
                                    <label>City</label>
                                    <input type="text">
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-12 col-12">
                                <div class="input-blocks">
                                    <label>Address</label>
                                    <input type="text">
                                </div>
                            </div> -->
            </div>
            <div class="modal-footer d-sm-flex justify-content-end">
              <button
                type="button"
                id="close-create-new-customer-modal"
                class="btn btn-cancel"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button type="submit" class="btn btn-submit me-2">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- Hold -->
  <div
    class="modal fade modal-default pos-modal"
    id="hold-order"
    aria-labelledby="hold-order"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header p-4">
          <h5>Hold order</h5>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body p-4">
          <form @submit.prevent="submitForm">
            <h2 class="text-center p-4">4500.00</h2>
            <div class="input-block">
              <label>Order Reference</label>
              <input class="form-control" type="text" value="" placeholder="" />
            </div>
            <p>
              The current order will be set on hold. You can retreive this order
              from the pending order button. Providing a reference to it might
              help you to identify the order more quickly.
            </p>
            <div class="modal-footer d-sm-flex justify-content-end">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button type="submit" class="btn btn-primary">Confirm</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Hold -->

  <!-- Edit Product -->
  <div
    class="modal fade modal-default pos-modal"
    id="edit-product"
    aria-labelledby="edit-product"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header p-4">
          <h5>Red Nike Laser</h5>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body p-4">
          <form @submit.prevent="submitForm">
            <div class="row">
              <div class="col-lg-6 col-sm-12 col-12">
                <div class="input-blocks add-product">
                  <label>Product Name <span>*</span></label>
                  <input type="text" placeholder="45" />
                </div>
              </div>
              <div class="col-lg-6 col-sm-12 col-12">
                <div class="input-blocks add-product">
                  <label>Tax Type <span>*</span></label>
                  <vue-select
                    :options="Tax"
                    id="taxtype"
                    placeholder="Exclusive"
                  />
                </div>
              </div>
              <div class="col-lg-6 col-sm-12 col-12">
                <div class="input-blocks add-product">
                  <label>Tax <span>*</span></label>
                  <input type="text" placeholder="% 15" />
                </div>
              </div>
              <div class="col-lg-6 col-sm-12 col-12">
                <div class="input-blocks add-product">
                  <label>Discount Type <span>*</span></label>
                  <vue-select :options="Discount" placeholder="Percentage" />
                </div>
              </div>
              <div class="col-lg-6 col-sm-12 col-12">
                <div class="input-blocks add-product">
                  <label>Discount <span>*</span></label>
                  <input type="text" placeholder="15" />
                </div>
              </div>
              <div class="col-lg-6 col-sm-12 col-12">
                <div class="input-blocks add-product">
                  <label>Sale Unit <span>*</span></label>
                  <vue-select
                    :options="Sale"
                    id="saleunit"
                    placeholder="Kilogram"
                  />
                </div>
              </div>
            </div>

            <div class="modal-footer d-sm-flex justify-content-end">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button type="submit" class="btn btn-primary">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Edit Product -->

  <!-- Recent Transactions -->
  <div
    class="modal fade pos-modal"
    id="recents"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header p-4 d-flex align-items-center justify-content-between">
          <h5 class="modal-title">Recent Sales</h5>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        
        <div class="modal-body p-4">
          <div class="spinner-container d-flex justify-content-center align-items-center" style="height: 50vh"
          v-if="loading">
            <div class="spinner-grow text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <div v-else-if="sales.length > 0" class="tabs-sets">
            <!-- <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active"
                  id="purchase-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#purchase"
                  type="button"
                  aria-controls="purchase"
                  aria-selected="true"
                  role="tab"
                >
                  Purchase
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="payment-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#payment"
                  type="button"
                  aria-controls="payment"
                  aria-selected="false"
                  role="tab"
                >
                  Payment
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="return-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#return"
                  type="button"
                  aria-controls="return"
                  aria-selected="false"
                  role="tab"
                >
                  Return
                </button>
              </li>
            </ul> -->
            <div class="tab-content">
              <div
                class="tab-pane fade show active"
                id="purchase"
                role="tabpanel"
                aria-labelledby="purchase-tab"
              >
                <div class="table-top">
                  <div class="search-set">
                    <div class="search-input">
                      <a
                        class="btn btn-searchset d-flex align-items-center h-100"
                        ><img
                          src="@/assets/img/icons/search-white.svg"
                          alt="img"
                      /></a>
                    </div>
                  </div>
                  <!-- <div class="wordset">
                    <ul>
                      <li>
                        <a
                          class="d-flex align-items-center justify-content-center"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Pdf"
                          ><img src="@/assets/img/icons/pdf.svg" alt="img"
                        /></a>
                      </li>
                      <li>
                        <a
                          class="d-flex align-items-center justify-content-center"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Excel"
                          ><img src="@/assets/img/icons/excel.svg" alt="img"
                        /></a>
                      </li>
                      <li>
                        <a
                          class="d-flex align-items-center justify-content-center"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Print"
                          ><vue-feather
                            type="printer"
                            class="printer"
                          ></vue-feather
                        ></a>
                      </li>
                    </ul>
                  </div> -->
                </div>

                <div class="table-responsive">
                  <table class="table datanew">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Reference</th>
                        <th>Customer</th>
                        <th>Amount (GH₵)</th>
                        <th class="d-none no-sort">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="sale in sales" :key="sale.id">
                        <td>{{ sale.formattedCreatedAt }}</td>
                        <td>{{ sale.reference_id }}</td>
                        <td>{{ sale.customer_profile.fullName }}</td>
                        <td>{{ sale.total_bill.toFixed(2) }}</td>
                        <td class="d-none action-table-data">
                          <div class="edit-delete-action">
                            <a class="me-2 p-2" href="javascript:void(0);"
                              ><vue-feather
                                type="eye"
                                class="feather-eye"
                              ></vue-feather
                            ></a>
                            <!-- <a class="me-2 p-2" href="javascript:void(0);"
                              ><vue-feather
                                type="edit"
                                class="feather-edit"
                              ></vue-feather
                            ></a>
                            <a
                              class="p-2 confirm-text"
                              href="javascript:void(0);"
                              ><vue-feather
                                type="trash-2"
                                class="feather-trash-2"
                              ></vue-feather
                            ></a> -->
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!-- <div class="tab-pane fade" id="payment" role="tabpanel">
                <div class="table-top">
                  <div class="search-set">
                    <div class="search-input">
                      <a
                        class="btn btn-searchset d-flex align-items-center h-100"
                        ><img
                          src="@/assets/img/icons/search-white.svg"
                          alt="img"
                      /></a>
                    </div>
                  </div>
                  <div class="wordset">
                    <ul>
                      <li>
                        <a
                          class="d-flex align-items-center justify-content-center"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Pdf"
                          ><img src="@/assets/img/icons/pdf.svg" alt="img"
                        /></a>
                      </li>
                      <li>
                        <a
                          class="d-flex align-items-center justify-content-center"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Excel"
                          ><img src="@/assets/img/icons/excel.svg" alt="img"
                        /></a>
                      </li>
                      <li>
                        <a
                          class="d-flex align-items-center justify-content-center"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Print"
                          ><vue-feather
                            type="printer"
                            class="printer"
                          ></vue-feather
                        ></a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="table-responsive">
                  <table class="table datanew">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Reference</th>
                        <th>Customer</th>
                        <th>Amount</th>
                        <th class="no-sort">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>19 Jan 2023</td>
                        <td>INV/SL0101</td>
                        <td>Walk-in Customer</td>
                        <td>GH₵1500.00</td>
                        <td class="action-table-data">
                          <div class="edit-delete-action">
                            <a class="me-2 p-2" href="javascript:void(0);"
                              ><vue-feather
                                type="eye"
                                class="feather-eye"
                              ></vue-feather
                            ></a>
                            <a class="me-2 p-2" href="javascript:void(0);"
                              ><vue-feather
                                type="edit"
                                class="feather-edit"
                              ></vue-feather
                            ></a>
                            <a
                              class="p-2 confirm-text"
                              href="javascript:void(0);"
                              ><vue-feather
                                type="trash-2"
                                class="feather-trash-2"
                              ></vue-feather
                            ></a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>19 Jan 2023</td>
                        <td>INV/SL0102</td>
                        <td>Walk-in Customer</td>
                        <td>GH₵1500.00</td>
                        <td class="action-table-data">
                          <div class="edit-delete-action">
                            <a class="me-2 p-2" href="javascript:void(0);"
                              ><vue-feather
                                type="eye"
                                class="feather-eye"
                              ></vue-feather
                            ></a>
                            <a class="me-2 p-2" href="javascript:void(0);"
                              ><vue-feather
                                type="edit"
                                class="feather-edit"
                              ></vue-feather
                            ></a>
                            <a
                              class="p-2 confirm-text"
                              href="javascript:void(0);"
                              ><vue-feather
                                type="trash-2"
                                class="feather-trash-2"
                              ></vue-feather
                            ></a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>19 Jan 2023</td>
                        <td>INV/SL0103</td>
                        <td>Walk-in Customer</td>
                        <td>GH₵1500.00</td>
                        <td class="action-table-data">
                          <div class="edit-delete-action">
                            <a class="me-2 p-2" href="javascript:void(0);"
                              ><vue-feather
                                type="eye"
                                class="feather-eye"
                              ></vue-feather
                            ></a>
                            <a class="me-2 p-2" href="javascript:void(0);"
                              ><vue-feather
                                type="edit"
                                class="feather-edit"
                              ></vue-feather
                            ></a>
                            <a
                              class="p-2 confirm-text"
                              href="javascript:void(0);"
                              ><vue-feather
                                type="trash-2"
                                class="feather-trash-2"
                              ></vue-feather
                            ></a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>19 Jan 2023</td>
                        <td>INV/SL0104</td>
                        <td>Walk-in Customer</td>
                        <td>GH₵1500.00</td>
                        <td class="action-table-data">
                          <div class="edit-delete-action">
                            <a class="me-2 p-2" href="javascript:void(0);"
                              ><vue-feather
                                type="eye"
                                class="feather-eye"
                              ></vue-feather
                            ></a>
                            <a class="me-2 p-2" href="javascript:void(0);"
                              ><vue-feather
                                type="edit"
                                class="feather-edit"
                              ></vue-feather
                            ></a>
                            <a
                              class="p-2 confirm-text"
                              href="javascript:void(0);"
                              ><vue-feather
                                type="trash-2"
                                class="feather-trash-2"
                              ></vue-feather
                            ></a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>19 Jan 2023</td>
                        <td>INV/SL0105</td>
                        <td>Walk-in Customer</td>
                        <td>GH₵1500.00</td>
                        <td class="action-table-data">
                          <div class="edit-delete-action">
                            <a class="me-2 p-2" href="javascript:void(0);"
                              ><vue-feather
                                type="eye"
                                class="feather-eye"
                              ></vue-feather
                            ></a>
                            <a class="me-2 p-2" href="javascript:void(0);"
                              ><vue-feather
                                type="edit"
                                class="feather-edit"
                              ></vue-feather
                            ></a>
                            <a
                              class="p-2 confirm-text"
                              href="javascript:void(0);"
                              ><vue-feather
                                type="trash-2"
                                class="feather-trash-2"
                              ></vue-feather
                            ></a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>19 Jan 2023</td>
                        <td>INV/SL0106</td>
                        <td>Walk-in Customer</td>
                        <td>GH₵1500.00</td>
                        <td class="action-table-data">
                          <div class="edit-delete-action">
                            <a class="me-2 p-2" href="javascript:void(0);"
                              ><vue-feather
                                type="eye"
                                class="feather-eye"
                              ></vue-feather
                            ></a>
                            <a class="me-2 p-2" href="javascript:void(0);"
                              ><vue-feather
                                type="edit"
                                class="feather-edit"
                              ></vue-feather
                            ></a>
                            <a
                              class="p-2 confirm-text"
                              href="javascript:void(0);"
                              ><vue-feather
                                type="trash-2"
                                class="feather-trash-2"
                              ></vue-feather
                            ></a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>19 Jan 2023</td>
                        <td>INV/SL0107</td>
                        <td>Walk-in Customer</td>
                        <td>GH₵1500.00</td>
                        <td class="action-table-data">
                          <div class="edit-delete-action">
                            <a class="me-2 p-2" href="javascript:void(0);"
                              ><vue-feather
                                type="eye"
                                class="feather-eye"
                              ></vue-feather
                            ></a>
                            <a class="me-2 p-2" href="javascript:void(0);"
                              ><vue-feather
                                type="edit"
                                class="feather-edit"
                              ></vue-feather
                            ></a>
                            <a
                              class="p-2 confirm-text"
                              href="javascript:void(0);"
                              ><vue-feather
                                type="trash-2"
                                class="feather-trash-2"
                              ></vue-feather
                            ></a>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="tab-pane fade" id="return" role="tabpanel">
                <div class="table-top">
                  <div class="search-set">
                    <div class="search-input">
                      <a
                        class="btn btn-searchset d-flex align-items-center h-100"
                        ><img
                          src="@/assets/img/icons/search-white.svg"
                          alt="img"
                      /></a>
                    </div>
                  </div>
                  <div class="wordset">
                    <ul>
                      <li>
                        <a
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Pdf"
                          class="d-flex align-items-center justify-content-center"
                          ><img src="@/assets/img/icons/pdf.svg" alt="img"
                        /></a>
                      </li>
                      <li>
                        <a
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Excel"
                          class="d-flex align-items-center justify-content-center"
                          ><img src="@/assets/img/icons/excel.svg" alt="img"
                        /></a>
                      </li>
                      <li>
                        <a
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Print"
                          class="d-flex align-items-center justify-content-center"
                          ><vue-feather
                            type="printer"
                            class="printer"
                          ></vue-feather
                        ></a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="table-responsive">
                  <table class="table datanew">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Reference</th>
                        <th>Customer</th>
                        <th>Amount</th>
                        <th class="no-sort">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>19 Jan 2023</td>
                        <td>INV/SL0101</td>
                        <td>Walk-in Customer</td>
                        <td>GH₵1500.00</td>
                        <td class="action-table-data">
                          <div class="edit-delete-action">
                            <a class="me-2 p-2" href="javascript:void(0);"
                              ><vue-feather
                                type="eye"
                                class="feather-eye"
                              ></vue-feather
                            ></a>
                            <a class="me-2 p-2" href="javascript:void(0);"
                              ><vue-feather
                                type="edit"
                                class="feather-edit"
                              ></vue-feather
                            ></a>
                            <a
                              class="p-2 confirm-text"
                              href="javascript:void(0);"
                              ><vue-feather
                                type="trash-2"
                                class="feather-trash-2"
                              ></vue-feather
                            ></a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>19 Jan 2023</td>
                        <td>INV/SL0102</td>
                        <td>Walk-in Customer</td>
                        <td>GH₵1500.00</td>
                        <td class="action-table-data">
                          <div class="edit-delete-action">
                            <a class="me-2 p-2" href="javascript:void(0);"
                              ><vue-feather
                                type="eye"
                                class="feather-eye"
                              ></vue-feather
                            ></a>
                            <a class="me-2 p-2" href="javascript:void(0);"
                              ><vue-feather
                                type="edit"
                                class="feather-edit"
                              ></vue-feather
                            ></a>
                            <a
                              class="p-2 confirm-text"
                              href="javascript:void(0);"
                              ><vue-feather
                                type="trash-2"
                                class="feather-trash-2"
                              ></vue-feather
                            ></a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>19 Jan 2023</td>
                        <td>INV/SL0103</td>
                        <td>Walk-in Customer</td>
                        <td>GH₵1500.00</td>
                        <td class="action-table-data">
                          <div class="edit-delete-action">
                            <a class="me-2 p-2" href="javascript:void(0);"
                              ><vue-feather
                                type="eye"
                                class="feather-eye"
                              ></vue-feather
                            ></a>
                            <a class="me-2 p-2" href="javascript:void(0);"
                              ><vue-feather
                                type="edit"
                                class="feather-edit"
                              ></vue-feather
                            ></a>
                            <a
                              class="p-2 confirm-text"
                              href="javascript:void(0);"
                              ><vue-feather
                                type="trash-2"
                                class="feather-trash-2"
                              ></vue-feather
                            ></a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>19 Jan 2023</td>
                        <td>INV/SL0104</td>
                        <td>Walk-in Customer</td>
                        <td>GH₵1500.00</td>
                        <td class="action-table-data">
                          <div class="edit-delete-action">
                            <a class="me-2 p-2" href="javascript:void(0);"
                              ><vue-feather
                                type="eye"
                                class="feather-eye"
                              ></vue-feather
                            ></a>
                            <a class="me-2 p-2" href="javascript:void(0);"
                              ><vue-feather
                                type="edit"
                                class="feather-edit"
                              ></vue-feather
                            ></a>
                            <a
                              class="p-2 confirm-text"
                              href="javascript:void(0);"
                              ><vue-feather
                                type="trash-2"
                                class="feather-trash-2"
                              ></vue-feather
                            ></a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>19 Jan 2023</td>
                        <td>INV/SL0105</td>
                        <td>Walk-in Customer</td>
                        <td>GH₵1500.00</td>
                        <td class="action-table-data">
                          <div class="edit-delete-action">
                            <a class="me-2 p-2" href="javascript:void(0);"
                              ><vue-feather
                                type="eye"
                                class="feather-eye"
                              ></vue-feather
                            ></a>
                            <a class="me-2 p-2" href="javascript:void(0);"
                              ><vue-feather
                                type="edit"
                                class="feather-edit"
                              ></vue-feather
                            ></a>
                            <a
                              class="p-2 confirm-text"
                              href="javascript:void(0);"
                              ><vue-feather
                                type="trash-2"
                                class="feather-trash-2"
                              ></vue-feather
                            ></a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>19 Jan 2023</td>
                        <td>INV/SL0106</td>
                        <td>Walk-in Customer</td>
                        <td>GH₵1500.00</td>
                        <td class="action-table-data">
                          <div class="edit-delete-action">
                            <a class="me-2 p-2" href="javascript:void(0);"
                              ><vue-feather
                                type="eye"
                                class="feather-eye"
                              ></vue-feather
                            ></a>
                            <a class="me-2 p-2" href="javascript:void(0);"
                              ><vue-feather
                                type="edit"
                                class="feather-edit"
                              ></vue-feather
                            ></a>
                            <a
                              class="p-2 confirm-text"
                              href="javascript:void(0);"
                              ><vue-feather
                                type="trash-2"
                                class="feather-trash-2"
                              ></vue-feather
                            ></a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>19 Jan 2023</td>
                        <td>INV/SL0107</td>
                        <td>Walk-in Customer</td>
                        <td>GH₵1500.00</td>
                        <td class="action-table-data">
                          <div class="edit-delete-action">
                            <a class="me-2 p-2" href="javascript:void(0);"
                              ><vue-feather
                                type="eye"
                                class="feather-eye"
                              ></vue-feather
                            ></a>
                            <a class="me-2 p-2" href="javascript:void(0);"
                              ><vue-feather
                                type="edit"
                                class="feather-edit"
                              ></vue-feather
                            ></a>
                            <a
                              class="p-2 confirm-text"
                              href="javascript:void(0);"
                              ><vue-feather
                                type="trash-2"
                                class="feather-trash-2"
                              ></vue-feather
                            ></a>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div> -->
            </div>
          </div>
          <div class="d-flex justify-content-center align-items-center" style="height: 50vh"
          v-else>
              <span>No sales to show</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Recent Transactions -->

  <!-- Recent Orders -->
  <div
    class="modal fade pos-modal"
    id="orders"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-md modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header p-4">
          <h5 class="modal-title">Orders</h5>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body p-4">
          <div class="tabs-sets">
            <ul class="nav nav-tabs" id="myTabs" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active"
                  id="onhold-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#onhold"
                  type="button"
                  aria-controls="onhold"
                  aria-selected="true"
                  role="tab"
                >
                  Onhold
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="unpaid-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#unpaid"
                  type="button"
                  aria-controls="unpaid"
                  aria-selected="false"
                  role="tab"
                >
                  Unpaid
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="paid-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#paid"
                  type="button"
                  aria-controls="paid"
                  aria-selected="false"
                  role="tab"
                >
                  Paid
                </button>
              </li>
            </ul>
            <div class="tab-content">
              <div
                class="tab-pane fade show active"
                id="onhold"
                role="tabpanel"
                aria-labelledby="onhold-tab"
              >
                <div class="table-top">
                  <div class="search-set w-100 search-order">
                    <div class="search-input w-100">
                      <a
                        class="btn btn-searchset d-flex align-items-center h-100"
                        ><img
                          src="@/assets/img/icons/search-white.svg"
                          alt="img"
                      /></a>
                    </div>
                  </div>
                </div>
                <div class="order-body">
                  <div class="default-cover p-4 mb-4">
                    <span class="badge bg-secondary d-inline-block mb-4"
                      >Order ID : #666659</span
                    >
                    <div class="row">
                      <div class="col-sm-12 col-md-6 record mb-3">
                        <table>
                          <tr class="mb-3">
                            <td>Cashier</td>
                            <td class="colon">:</td>
                            <td class="text">admin</td>
                          </tr>
                          <tr>
                            <td>Customer</td>
                            <td class="colon">:</td>
                            <td class="text">Botsford</td>
                          </tr>
                        </table>
                      </div>
                      <div class="col-sm-12 col-md-6 record mb-3">
                        <table>
                          <tr>
                            <td>Total</td>
                            <td class="colon">:</td>
                            <td class="text">GH₵900</td>
                          </tr>
                          <tr>
                            <td>Date</td>
                            <td class="colon">:</td>
                            <td class="text">29-08-2023 13:39:11</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <p class="p-4">Customer need to recheck the product once</p>
                    <div
                      class="btn-row d-sm-flex align-items-center justify-content-between"
                    >
                      <a
                        href="javascript:void(0);"
                        class="btn btn-info btn-icon flex-fill"
                        >Open</a
                      >
                      <a
                        href="javascript:void(0);"
                        class="btn btn-danger btn-icon flex-fill"
                        >Products</a
                      >
                      <a
                        href="javascript:void(0);"
                        class="btn btn-success btn-icon flex-fill"
                        >Print</a
                      >
                    </div>
                  </div>
                  <div class="default-cover p-4 mb-4">
                    <span class="badge bg-secondary d-inline-block mb-4"
                      >Order ID : #666660</span
                    >
                    <div class="row">
                      <div class="col-sm-12 col-md-6 record mb-3">
                        <table>
                          <tr class="mb-3">
                            <td>Cashier</td>
                            <td class="colon">:</td>
                            <td class="text">admin</td>
                          </tr>
                          <tr>
                            <td>Customer</td>
                            <td class="colon">:</td>
                            <td class="text">Smith</td>
                          </tr>
                        </table>
                      </div>
                      <div class="col-sm-12 col-md-6 record mb-3">
                        <table>
                          <tr>
                            <td>Total</td>
                            <td class="colon">:</td>
                            <td class="text">GH₵15000</td>
                          </tr>
                          <tr>
                            <td>Date</td>
                            <td class="colon">:</td>
                            <td class="text">30-08-2023 15:59:11</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <p class="p-4">Customer need to recheck the product once</p>
                    <div
                      class="btn-row d-flex align-items-center justify-content-between"
                    >
                      <a
                        href="javascript:void(0);"
                        class="btn btn-info btn-icon flex-fill"
                        >Open</a
                      >
                      <a
                        href="javascript:void(0);"
                        class="btn btn-danger btn-icon flex-fill"
                        >Products</a
                      >
                      <a
                        href="javascript:void(0);"
                        class="btn btn-success btn-icon flex-fill"
                        >Print</a
                      >
                    </div>
                  </div>
                  <div class="default-cover p-4">
                    <span class="badge bg-secondary d-inline-block mb-4"
                      >Order ID : #666661</span
                    >
                    <div class="row">
                      <div class="col-sm-12 col-md-6 record mb-3">
                        <table>
                          <tr class="mb-3">
                            <td>Cashier</td>
                            <td class="colon">:</td>
                            <td class="text">admin</td>
                          </tr>
                          <tr>
                            <td>Customer</td>
                            <td class="colon">:</td>
                            <td class="text">John David</td>
                          </tr>
                        </table>
                      </div>
                      <div class="col-sm-12 col-md-6 record mb-3">
                        <table>
                          <tr>
                            <td>Total</td>
                            <td class="colon">:</td>
                            <td class="text">GH₵2000</td>
                          </tr>
                          <tr>
                            <td>Date</td>
                            <td class="colon">:</td>
                            <td class="text">01-09-2023 13:15:00</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <p class="p-4 mb-4">
                      Customer need to recheck the product once
                    </p>
                    <div
                      class="btn-row d-flex align-items-center justify-content-between"
                    >
                      <a
                        href="javascript:void(0);"
                        class="btn btn-info btn-icon flex-fill"
                        >Open</a
                      >
                      <a
                        href="javascript:void(0);"
                        class="btn btn-danger btn-icon flex-fill"
                        >Products</a
                      >
                      <a
                        href="javascript:void(0);"
                        class="btn btn-success btn-icon flex-fill"
                        >Print</a
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="unpaid" role="tabpanel">
                <div class="table-top">
                  <div class="search-set w-100 search-order">
                    <div class="search-input">
                      <a
                        class="btn btn-searchset d-flex align-items-center h-100"
                        ><img
                          src="@/assets/img/icons/search-white.svg"
                          alt="img"
                      /></a>
                    </div>
                  </div>
                </div>
                <div class="order-body">
                  <div class="default-cover p-4 mb-4">
                    <span class="badge bg-info d-inline-block mb-4"
                      >Order ID : #666662</span
                    >
                    <div class="row">
                      <div class="col-sm-12 col-md-6 record mb-3">
                        <table>
                          <tr class="mb-3">
                            <td>Cashier</td>
                            <td class="colon">:</td>
                            <td class="text">admin</td>
                          </tr>
                          <tr>
                            <td>Customer</td>
                            <td class="colon">:</td>
                            <td class="text">Anastasia</td>
                          </tr>
                        </table>
                      </div>
                      <div class="col-sm-12 col-md-6 record mb-3">
                        <table>
                          <tr>
                            <td>Total</td>
                            <td class="colon">:</td>
                            <td class="text">GH₵2500</td>
                          </tr>
                          <tr>
                            <td>Date</td>
                            <td class="colon">:</td>
                            <td class="text">10-09-2023 17:15:11</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <p class="p-4">Customer need to recheck the product once</p>
                    <div
                      class="btn-row d-flex align-items-center justify-content-between"
                    >
                      <a
                        href="javascript:void(0);"
                        class="btn btn-info btn-icon flex-fill"
                        >Open</a
                      >
                      <a
                        href="javascript:void(0);"
                        class="btn btn-danger btn-icon flex-fill"
                        >Products</a
                      >
                      <a
                        href="javascript:void(0);"
                        class="btn btn-success btn-icon flex-fill"
                        >Print</a
                      >
                    </div>
                  </div>
                  <div class="default-cover p-4 mb-4">
                    <span class="badge bg-info d-inline-block mb-4"
                      >Order ID : #666663</span
                    >
                    <div class="row">
                      <div class="col-sm-12 col-md-6 record mb-3">
                        <table>
                          <tr class="mb-3">
                            <td>Cashier</td>
                            <td class="colon">:</td>
                            <td class="text">admin</td>
                          </tr>
                          <tr>
                            <td>Customer</td>
                            <td class="colon">:</td>
                            <td class="text">Lucia</td>
                          </tr>
                        </table>
                      </div>
                      <div class="col-sm-12 col-md-6 record mb-3">
                        <table>
                          <tr>
                            <td>Total</td>
                            <td class="colon">:</td>
                            <td class="text">GH₵1500</td>
                          </tr>
                          <tr>
                            <td>Date</td>
                            <td class="colon">:</td>
                            <td class="text">11-09-2023 14:50:11</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <p class="p-4">Customer need to recheck the product once</p>
                    <div
                      class="btn-row d-flex align-items-center justify-content-between"
                    >
                      <a
                        href="javascript:void(0);"
                        class="btn btn-info btn-icon flex-fill"
                        >Open</a
                      >
                      <a
                        href="javascript:void(0);"
                        class="btn btn-danger btn-icon flex-fill"
                        >Products</a
                      >
                      <a
                        href="javascript:void(0);"
                        class="btn btn-success btn-icon flex-fill"
                        >Print</a
                      >
                    </div>
                  </div>
                  <div class="default-cover p-4 mb-4">
                    <span class="badge bg-info d-inline-block mb-4"
                      >Order ID : #666664</span
                    >
                    <div class="row">
                      <div class="col-sm-12 col-md-6 record mb-3">
                        <table>
                          <tr class="mb-3">
                            <td>Cashier</td>
                            <td class="colon">:</td>
                            <td class="text">admin</td>
                          </tr>
                          <tr>
                            <td>Customer</td>
                            <td class="colon">:</td>
                            <td class="text">Diego</td>
                          </tr>
                        </table>
                      </div>
                      <div class="col-sm-12 col-md-6 record mb-3">
                        <table>
                          <tr>
                            <td>Total</td>
                            <td class="colon">:</td>
                            <td class="text">GH₵30000</td>
                          </tr>
                          <tr>
                            <td>Date</td>
                            <td class="colon">:</td>
                            <td class="text">12-09-2023 17:22:11</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <p class="p-4 mb-4">
                      Customer need to recheck the product once
                    </p>
                    <div
                      class="btn-row d-flex align-items-center justify-content-between"
                    >
                      <a
                        href="javascript:void(0);"
                        class="btn btn-info btn-icon flex-fill"
                        >Open</a
                      >
                      <a
                        href="javascript:void(0);"
                        class="btn btn-danger btn-icon flex-fill"
                        >Products</a
                      >
                      <a
                        href="javascript:void(0);"
                        class="btn btn-success btn-icon flex-fill"
                        >Print</a
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="paid" role="tabpanel">
                <div class="table-top">
                  <div class="search-set w-100 search-order">
                    <div class="search-input">
                      <a
                        class="btn btn-searchset d-flex align-items-center h-100"
                        ><img
                          src="@/assets/img/icons/search-white.svg"
                          alt="img"
                      /></a>
                    </div>
                  </div>
                </div>
                <div class="order-body">
                  <div class="default-cover p-4 mb-4">
                    <span class="badge bg-primary d-inline-block mb-4"
                      >Order ID : #666665</span
                    >
                    <div class="row">
                      <div class="col-sm-12 col-md-6 record mb-3">
                        <table>
                          <tr class="mb-3">
                            <td>Cashier</td>
                            <td class="colon">:</td>
                            <td class="text">admin</td>
                          </tr>
                          <tr>
                            <td>Customer</td>
                            <td class="colon">:</td>
                            <td class="text">Hugo</td>
                          </tr>
                        </table>
                      </div>
                      <div class="col-sm-12 col-md-6 record mb-3">
                        <table>
                          <tr>
                            <td>Total</td>
                            <td class="colon">:</td>
                            <td class="text">GH₵5000</td>
                          </tr>
                          <tr>
                            <td>Date</td>
                            <td class="colon">:</td>
                            <td class="text">13-09-2023 19:39:11</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <p class="p-4">Customer need to recheck the product once</p>
                    <div
                      class="btn-row d-flex align-items-center justify-content-between"
                    >
                      <a
                        href="javascript:void(0);"
                        class="btn btn-info btn-icon flex-fill"
                        >Open</a
                      >
                      <a
                        href="javascript:void(0);"
                        class="btn btn-danger btn-icon flex-fill"
                        >Products</a
                      >
                      <a
                        href="javascript:void(0);"
                        class="btn btn-success btn-icon flex-fill"
                        >Print</a
                      >
                    </div>
                  </div>
                  <div class="default-cover p-4 mb-4">
                    <span class="badge bg-primary d-inline-block mb-4"
                      >Order ID : #666666</span
                    >
                    <div class="row">
                      <div class="col-sm-12 col-md-6 record mb-3">
                        <table>
                          <tr class="mb-3">
                            <td>Cashier</td>
                            <td class="colon">:</td>
                            <td class="text">admin</td>
                          </tr>
                          <tr>
                            <td>Customer</td>
                            <td class="colon">:</td>
                            <td class="text">Antonio</td>
                          </tr>
                        </table>
                      </div>
                      <div class="col-sm-12 col-md-6 record mb-3">
                        <table>
                          <tr>
                            <td>Total</td>
                            <td class="colon">:</td>
                            <td class="text">GH₵7000</td>
                          </tr>
                          <tr>
                            <td>Date</td>
                            <td class="colon">:</td>
                            <td class="text">15-09-2023 18:39:11</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <p class="p-4">Customer need to recheck the product once</p>
                    <div
                      class="btn-row d-flex align-items-center justify-content-between"
                    >
                      <a
                        href="javascript:void(0);"
                        class="btn btn-info btn-icon flex-fill"
                        >Open</a
                      >
                      <a
                        href="javascript:void(0);"
                        class="btn btn-danger btn-icon flex-fill"
                        >Products</a
                      >
                      <a
                        href="javascript:void(0);"
                        class="btn btn-success btn-icon flex-fill"
                        >Print</a
                      >
                    </div>
                  </div>
                  <div class="default-cover p-4 mb-4">
                    <span class="badge bg-primary d-inline-block mb-4"
                      >Order ID : #666667</span
                    >
                    <div class="row">
                      <div class="col-sm-12 col-md-6 record mb-3">
                        <table>
                          <tr class="mb-3">
                            <td>Cashier</td>
                            <td class="colon">:</td>
                            <td class="text">admin</td>
                          </tr>
                          <tr>
                            <td>Customer</td>
                            <td class="colon">:</td>
                            <td class="text">MacQuoid</td>
                          </tr>
                        </table>
                      </div>
                      <div class="col-sm-12 col-md-6 record mb-3">
                        <table>
                          <tr>
                            <td>Total</td>
                            <td class="colon">:</td>
                            <td class="text">GH₵7050</td>
                          </tr>
                          <tr>
                            <td>Date</td>
                            <td class="colon">:</td>
                            <td class="text">17-09-2023 19:39:11</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <p class="p-4 mb-4">
                      Customer need to recheck the product once
                    </p>
                    <div
                      class="btn-row d-flex align-items-center justify-content-between"
                    >
                      <a
                        href="javascript:void(0);"
                        class="btn btn-info btn-icon flex-fill"
                        >Open</a
                      >
                      <a
                        href="javascript:void(0);"
                        class="btn btn-danger btn-icon flex-fill"
                        >Products</a
                      >
                      <a
                        href="javascript:void(0);"
                        class="btn btn-success btn-icon flex-fill"
                        >Print</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Recent Orders -->

</template>

<script>
import { createCustomer } from "@/api/customers";
import { fetchStoreSales } from "@/api/fetchStoreSales";
import { formatTime } from "@/assets/js/utils";
import { selectedStoreState } from "@/state/storeState";

export default {
  emits: ["new-customer"],
  data() {
    return {
      Tax: ["Exclusive", "Inclusive"],
      Discount: ["Percentage", "Early payment discounts"],
      Sale: ["Kilogram", "Grams"],
      newCustomer: {
        first_name: "",
        last_name: "",
        email_address: "",
        phone_contact: "",
      },
      sales: [],
      loading: false,
    };
  },
  mounted() {
    const recentTransactionsModal = document.getElementById("recents");
    
    recentTransactionsModal.addEventListener("show.bs.modal", () => {
      this.fetchSales();
    });
  },
  methods: {
    async fetchSales() {
      try {
        this.loading = true; // Show loader before fetching data
        const sales = await fetchStoreSales(selectedStoreState.id);
        for (let sale of sales) {
          sale.formattedCreatedAt = formatTime(sale.created_at);
          sale.customer_profile.fullName =
            sale.customer_profile?.first_name +
            " " +
            sale.customer_profile?.last_name;
        }
        this.sales = sales;
        console.log(this.sales)
      } catch (error) {
        this.sales = [];
        this.emptyText = "Error: " + error.message;
        Swal.fire({
          title: "Unable To Fetch Sales",
          text: error?.response?.data?.message || error.message,
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        });
      } finally {
        this.loading = false; // Hide loader after fetching data
      }
    },
    submitForm() {
      const data = JSON.stringify({
        ...this.newCustomer,
        store: selectedStoreState.id,
      });

      // Send data to server here
      createCustomer(selectedStoreState.id, data)
        .then((d) => {
          // emit to pos interface to be set in cart
          this.$emit("new-customer", d.id);

          // clear form
          this.newCustomer = {
            first_name: "",
            last_name: "",
            email_address: "",
            phone_contact: "",
          };

          // click on the cancel button to close the modal
          document.getElementById("close-create-new-customer-modal").click();
        })
        .catch((error) => {
          Swal.fire({
            title: "Unable To Create New Customer",
            text: error.response?.data?.message || error.message,
            icon: "error",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          });
        });
    },
  },
};
</script>
