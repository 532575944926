<template>
  <layout-header></layout-header>

  <div class="page-wrapper pos-pg-wrapper ms-0">
    <div class="content pos-design p-0">
      <div class="btn-row d-sm-flex align-items-center">
        <!-- <a href="javascript:void(0);" class="btn btn-secondary mb-xs-3" data-bs-toggle="modal"
          data-bs-target="#orders"><span class="me-1 d-flex align-items-center"><vue-feather type="shopping-cart"
              class="feather-16"></vue-feather></span>View Orders</a> -->
        <!-- <a href="javascript:void(0);" class="btn btn-info"><span class="me-1 d-flex align-items-center"><vue-feather
              type="rotate-cw" class="feather-16"></vue-feather></span>Reset</a> -->
        <a href="javascript:void(0);" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#recents"><span
            class="me-1 d-flex align-items-center"><vue-feather type="refresh-ccw"
              class="feather-16"></vue-feather></span>Recent Transactions</a>
      </div>
      <!-- start of categories slide show -->

      <div class="row align-items-start pos-wrapper">
        <div class="col-md-12 col-lg-8">
          <div class="pos-categories tabs_wrapper">
            <!-- <h5>Categories</h5>
            <p>Select From Below Categories</p>
            <ul class="tabs owl-carousel pos-category">
              <Carousel
                :wrap-around="true"
                :settings="settings"
                :breakpoints="breakpoints"
              >
                <Slide v-for="item in POS" :key="item.product">
                  <li :id="item.Name">
                    <a href="javascript:void(0);">
                      <img
                        :src="require(`@/assets/img/categories/${item.Image}`)"
                        alt="Categories"
                      />
                    </a>
                    <h6>
                      <a href="javascript:void(0);">{{ item.Title }}</a>
                    </h6>
                    <span>{{ item.Items }}</span>
                  </li>
                </Slide>
                <template #addons>
                  <Navigation />
                </template>
</Carousel>
</ul> -->

            <!-- end of categories carousel -->

            <div class="search-wrapper mb-5">
              <h5 class="mb-3">Search for product to add to cart</h5>
              <div class="search-set">
                <div class="search-input w-100">
                  <input type="text" v-model="searchInput" placeholder="Search" class="dark-input w-100" />
                  <a href="" class="btn btn-searchset">
                    <i data-feather="search" class="feather-search"></i>
                  </a>
                </div>
              </div>
            </div>

            <div class="pos-products">
              <!-- <div class="d-flex align-items-center justify-content-between">
                <h5 class="mb-3">Products</h5>
              </div> -->
              <div class="tabs_container">
                <div class="tab_content active" data-tab="all">
                  <div class="row">
                    <div class="col-12 d-flex justify-content-center">
                      <!-- show loader -->
                      <div v-if="loading" class="p-5">
                        <div class="spinner-grow text-primary" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                    </div>
                    <!-- Product Cardss -->
                    <div class="row">
                      <div v-for="product in filteredProducts" :key="product.id"
                        class="col-sm-2 col-md-6 col-lg-3 col-xl-3">
                        <div :class="{
                          'product-info': true,
                          'default-cover': true,
                          card: true,
                          active: markAsAddedToCart(product.id),
                        }">
                          <a href="javascript:void(0);" class="img-bg overflow-hidden">
                            <img :src="product.image ||
                              require('@/assets/img/products/default-product-image.png')
                              " alt="Products" />
                            <span v-show="markAsAddedToCart(product.id)"><vue-feather type="check"
                                class="feather-16"></vue-feather></span>
                          </a>
                          <h6 class="cat-name">
                            <a href="javascript:void(0);">{{
                              product.category.category_name
                            }}</a>
                          </h6>
                          <h6 class="product-name">
                            <a href="javascript:void(0);">{{
                              product.product_name
                            }}</a>
                          </h6>
                          <div class="d-flex align-items-center justify-content-between price">
                            <span>{{ product.quantity_in_stock }} Pcs</span>
                            <p>GH₵{{ product.selling_price }}</p>
                          </div>

                          <div class="add-to-pos d-flex flex-column gap-3 mt-3">
                            <div class="product-quantity w-100 px-2">
                              <span class="quantity-btn" @click="subtractQuantity(product.id)"><vue-feather
                                  type="minus-circle" class="search"></vue-feather></span>

                              <input type="number" placeholder="quantity"
                                class="quntity-input flex-grow-1 text-black" min="1"
                                :id="`quantity-input-${product.id}`"
                                :disabled="product.quantity_in_stock <= 0"
                                />
                              <span class="quantity-btn" @click="addQuantity(product.id)"><vue-feather
                                  type="plus-circle" class="plus-circle"></vue-feather></span>
                            </div>
                            <!-- <select v-model="selectedProduct">
                            <option v-for="product in data" :key="product.id" :value="product">
                              {{ product.product_name }}
                            </option> -->
                            <button v-if="product.quantity_in_stock <= 0" @click="removeFromCart(product.id)"
                              class="btn w-100" disabled>
                              Out Of Stock
                            </button>
                            <button v-else-if="markAsAddedToCart(product.id)" @click="removeFromCart(product.id)"
                              class="btn btn-danger w-100">
                              Remove from Cart
                            </button>
                            <button v-else @click="addToCart(product)" class="btn btn-primary w-100">
                              Add to Cart
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- end of gpt -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 col-lg-4 ps-0">
          <aside class="product-order-list">
            <div class="head d-flex align-items-center justify-content-between w-100">
              <div class="">
                <h5>Sale #{{ saleId }}</h5>
                <span>Complete a customer's sale here</span>
              </div>
              <div class="">
                <a @click="resetSale" class="btn btn-info d-flex gap-2">
                  <span class="me-1 d-flex align-items-center">
                    <vue-feather type="rotate-cw" class="feather-16" stroke="white"></vue-feather>
                  </span>
                  Reset Sale
                </a>
                <!-- <a class="confirm-text" @click="showConfirmation" href="javascript:void(0);"><vue-feather type="trash-2"
                    class="feather-16 text-danger"></vue-feather></a>
                <a href="javascript:void(0);" class="text-default"><vue-feather type="more-vertical"
                    class="feather-16"></vue-feather></a> -->
              </div>
            </div>
            <div class="customer-info block-section">
              <h6>Customer Information</h6>
              <div class="input-block d-flex align-items-center">
                <div class="flex-grow-1">
                  <vue-select :options="customerChoices" id="walkin" placeholder="Walk in Customer"
                    v-model="selectedCustomer" />
                </div>
                <a href="javascript:void(0);" class="btn btn-primary btn-icon" data-bs-toggle="modal"
                  data-bs-target="#create"><vue-feather type="user-plus" class="feather-16"></vue-feather></a>
              </div>
              <!-- <div class="input-block">
                <vue-select :options="Airpod" id="airpod" placeholder="Search Products" />
              </div> -->
            </div>

            <div class="product-added block-section">
              <div class="head-text d-flex align-items-center justify-content-between">
                <h6 class="d-flex align-items-center mb-0">
                  Products Added<span class="count">{{
                    sale_items.length
                  }}</span>
                </h6>
                <a @click.prevent="clearSaleItems" class="d-flex align-items-center text-danger"><span class="me-1"
                    style="height: 16px"><vue-feather type="x" class="feather-16"></vue-feather></span>Clear all</a>
              </div>
              <div class="product-wrap">
                <!-- <div
                  class="product-list d-flex align-items-center justify-content-between"
                >
                  <div
                    class="d-flex align-items-center product-info"
                    data-bs-toggle="modal"
                    data-bs-target="#products"
                  >
                    <a href="javascript:void(0);" class="img-bg">
                      <img src="@/assets/img/products/pos-product-16.png" alt="Products" />
                    </a>
                    <div class="info">
                      <span>PT0005</span>
                      <h6><a href="javascript:void(0);">Red Nike Laser</a></h6>
                      <p>GH₵2000</p>
                    </div>
                  </div>
                  <div class="qty-item text-center">
                    <a href="javascript:void(0);" class="dec d-flex justify-content-center align-items-center"
                      data-bs-toggle="tooltip" data-bs-placement="top" title="minus"><vue-feather type="minus-circle"
                        class="feather-14"></vue-feather></a>
                    <input type="text" class="form-control text-center" name="qty" value="4" />
                    <a href="javascript:void(0);" class="inc d-flex justify-content-center align-items-center"
                      data-bs-toggle="tooltip" data-bs-placement="top" title="plus"><vue-feather type="plus-circle"
                        class="feather-14"></vue-feather></a>
                  </div>
                  <div class="d-flex align-items-center action">
                    <a class="btn-icon edit-icon me-2" href="javascript:void(0);" data-bs-toggle="modal"
                      data-bs-target="#edit-product">
                      <vue-feather type="edit" class="feather-14"></vue-feather>
                    </a>
                    <a class="btn-icon delete-icon confirm-text" href="javascript:void(0);">
                      <vue-feather type="trash-2" class="feather-14"></vue-feather>
                    </a>
                  </div>
                </div> -->
                <!-- <div
                  class="product-list d-flex align-items-center justify-content-between"
                >
                  <div
                    class="d-flex align-items-center product-info"
                    data-bs-toggle="modal"
                    data-bs-target="#products"
                  >
                    <a href="javascript:void(0);" class="img-bg">
                      <img src="@/assets/img/products/pos-product-17.png" alt="Products" />
                    </a>
                    <div class="info">
                      <span>PT0235</span>
                      <h6><a href="javascript:void(0);">Iphone 14</a></h6>
                      <p>GH₵3000</p>
                    </div>
                  </div>
                  <div class="qty-item text-center">
                    <a href="javascript:void(0);" class="dec d-flex justify-content-center align-items-center"
                      data-bs-toggle="tooltip" data-bs-placement="top" title="minus"><vue-feather type="minus-circle"
                        class="feather-14"></vue-feather></a>
                    <input type="text" class="form-control text-center" name="qty" value="3" />
                    <a href="javascript:void(0);" class="inc d-flex justify-content-center align-items-center"
                      data-bs-toggle="tooltip" data-bs-placement="top" title="plus"><vue-feather type="plus-circle"
                        class="feather-14"></vue-feather></a>
                  </div>
                  <div class="d-flex align-items-center action">
                    <a class="btn-icon edit-icon me-2" href="javascript:void(0);" data-bs-toggle="modal"
                      data-bs-target="#edit-product">
                      <vue-feather type="edit" class="feather-14"></vue-feather>
                    </a>
                    <a class="btn-icon delete-icon confirm-text" href="javascript:void(0);">
                      <vue-feather type="trash-2" class="feather-14"></vue-feather>
                    </a>
                  </div>
                </div>
                <div class="product-list d-flex align-items-center justify-content-between">
                  <div class="d-flex align-items-center product-info" data-bs-toggle="modal" data-bs-target="#products">
                    <a href="javascript:void(0);" class="img-bg">
                      <img src="@/assets/img/products/pos-product-16.png" alt="Products" />
                    </a>
                    <div class="info">
                      <span>PT0005</span>
                      <h6><a href="javascript:void(0);">Red Nike Laser</a></h6>
                      <p>GH₵2000</p>
                    </div>
                  </div>
                  <div class="qty-item text-center">
                    <a href="javascript:void(0);" class="dec d-flex justify-content-center align-items-center"
                      data-bs-toggle="tooltip" data-bs-placement="top" title="minus"><vue-feather type="minus-circle"
                        class="feather-14"></vue-feather></a>
                    <input type="text" class="form-control text-center" name="qty" value="1" />
                    <a href="javascript:void(0);" class="inc d-flex justify-content-center align-items-center"
                      data-bs-toggle="tooltip" data-bs-placement="top" title="plus"><vue-feather type="plus-circle"
                        class="feather-14"></vue-feather></a>
                  </div>
                  <div class="d-flex align-items-center action">
                    <a class="btn-icon edit-icon me-2" href="javascript:void(0);" data-bs-toggle="modal"
                      data-bs-target="#edit-product">
                      <vue-feather type="edit" class="feather-14"></vue-feather>
                    </a>
                    <a class="btn-icon delete-icon confirm-text" href="javascript:void(0);">
                      <vue-feather type="trash-2" class="feather-14"></vue-feather>
                    </a>
                  </div>
                </div> -->
                <!-- start of cart -->
                <!-- <div
                  class="product-list d-flex align-items-center justify-content-between"
                >
                  <div
                    class="d-flex align-items-center product-info"
                    data-bs-toggle="modal"
                    data-bs-target="#products"
                  >
                    <a href="javascript:void(0);" class="img-bg">
                      <img src="@/assets/img/products/pos-product-17.png" alt="Products" />
                    </a>
                    <div class="info">
                      <span>PT0005</span>
                      <h6><a href="javascript:void(0);">Red Nike Laser</a></h6>
                      <p>GH₵2000</p>
                    </div>
                  </div>
                  <div class="qty-item text-center">
                    <a href="javascript:void(0);" class="dec d-flex justify-content-center align-items-center"
                      data-bs-toggle="tooltip" data-bs-placement="top" title="minus"><vue-feather type="minus-circle"
                        class="feather-14"></vue-feather></a>
                    <input type="text" class="form-control text-center" name="qty" value="1" />
                    <a href="javascript:void(0);" class="inc d-flex justify-content-center align-items-center"
                      data-bs-toggle="tooltip" data-bs-placement="top" title="plus"><vue-feather type="plus-circle"
                        class="feather-14"></vue-feather></a>
                  </div>
                  <div class="d-flex align-items-center action">
                    <a class="btn-icon edit-icon me-2" href="javascript:void(0);" data-bs-toggle="modal"
                      data-bs-target="#edit-product">
                      <vue-feather type="edit" class="feather-14"></vue-feather>
                    </a>
                    <a class="btn-icon delete-icon confirm-text" href="javascript:void(0);">
                      <vue-feather type="trash-2" class="feather-14"></vue-feather>
                    </a>
                  </div>
                </div> -->
                <div v-if="sale_items.length > 0">
                  <div v-for="item in sale_items" :key="item.product"
                    class="product-list d-flex align-items-center justify-content-between">
                    <div class="d-flex align-items-center product-info">
                      <a href="javascript:void(0);" class="img-bg overflow-hidden">
                        <!-- Assuming you have an image URL or a placeholder image -->
                        <img :src="item.image ||
                          require('@/assets/img/products/default-product-image.png')
                          " alt="Product Image" />
                      </a>
                      <div class="info">
                        <!-- Displaying product reference ID or name -->
                        <span>{{ item.product_reference_id }}</span>
                        <h6>
                          <a href="javascript:void(0);">{{
                            item.product_name
                          }}</a>
                        </h6>
                        <!-- Displaying unit price -->
                        <p>GH₵{{ item.unit_price * item.quantity }}</p>
                      </div>
                    </div>
                    <div class="qty-item text-center">
                      <a href="javascript:void(0);" class="dec d-flex justify-content-center align-items-center"
                        data-bs-toggle="tooltip" data-bs-placement="top" title="minus"
                        @click="decrementQuantity(item.product)">
                        <vue-feather type="minus-circle"></vue-feather>
                      </a>
                      <input type="number" class="form-control text-center" :value="item.quantity"
                        @input="setQuantity($event, item.product)" />
                      <a href="javascript:void(0);" class="inc d-flex justify-content-center align-items-center"
                        data-bs-toggle="tooltip" data-bs-placement="top" title="plus"
                        @click="incrementQuantity(item.product)">
                        <vue-feather type="plus-circle"></vue-feather>
                      </a>
                    </div>
                    <div class="d-flex align-items-center action">
                      <!-- <a class="btn-icon edit-icon me-2" href="javascript:void(0);" data-bs-toggle="modal"
                        data-bs-target="#edit-product">
                        <vue-feather type="edit" class="feather-14"></vue-feather>
                      </a> -->
                      <a class="btn-icon delete-icon confirm-text" href="javascript:void(0);"
                        @click="removeFromCart(item.product)">
                        <vue-feather type="trash-2" class="feather-14"></vue-feather>
                      </a>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <p>No sale items found.</p>
                </div>
                <!-- end of cart -->
              </div>
            </div>
            <div class="block-section">
              <div class="selling-info">
                <div class="row">
                  <!-- <div class="col-12 col-sm-4">
                    <div class="input-block">
                      <label>Order Tax</label>
                      <vue-select :options="GST" id="gst" placeholder="GST 5%" />
                    </div>
                  </div> -->
                  <div class="col-12 col-sm-6">
                    <div class="input-block">
                      <label>Discount Type</label>
                      <vue-select :options="discountTypeChoices" v-model="discountType" id="shippingf" />
                    </div>
                  </div>
                  <div class="col-12 col-sm-6">
                    <div class="input-block">
                      <label>Discount Value</label>
                      <input type="number" :disabled="this.sale_items.length == 0" v-model.number="discountValue"
                        class="flex-grow-1 form-control" min="0" default="0" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="order-total">
                <table class="table table-responsive table-borderless">
                  <tr>
                    <td>Sub Total</td>
                    <td class="text-end">GH₵{{ subTotal.toFixed(2) }}</td>
                  </tr>
                  <!-- <tr>
                    <td>Tax (GST 5%)</td>
                    <td class="text-end">GH₵40.21</td>
                  </tr> -->
                  <!-- <tr>
                    <td>Shipping</td>
                    <td class="text-end">GH₵40.21</td>
                  </tr> -->
                  <tr>
                    <td class="danger">
                      Discount
                      {{
                        discountType == "Percentage"
                          ? `(${discountValue}%)`
                          : ""
                      }}
                    </td>
                    <td class="danger text-end">
                      GH₵{{ calculatedDiscount.toFixed(2) }}
                    </td>
                  </tr>
                  <tr v-show="selectedSaleType == 'credit-sale'">
                    <td>Initial Payment</td>
                    <td class="text-end">GH₵{{ invoiceInitialPayment.toFixed(2) }}</td>
                  </tr>
                  <tr class="success">
                    <td v-if="selectedSaleType == 'instant-sale'">TOTAL BILL</td>
                    <td v-else>TOTAL DUE</td>
                    <td class="text-end">GH₵{{ totalBill.toFixed(2) }}</td>
                  </tr>
                </table>
              </div>
            </div>

            <div class="block-section sale-type">
              <h6>Sale Type</h6>
              <div class="d-flex gap-5">
                <div class="d-flex align-items-center">
                  <label class="checkboxs"><input type="radio" id="instant-sale" v-model="selectedSaleType"
                      value="instant-sale"><span class="checkmarks"></span></label>
                  <label for="instant-sale">Instant Sale</label>
                </div>
                <div class="d-flex align-items-center">
                  <label class="checkboxs"><input type="radio" id="credit-sale" v-model="selectedSaleType"
                      value="credit-sale"><span class="checkmarks"></span></label>
                  <label for="credit-sale">Credit Sale</label>
                </div>
              </div>


            </div>

            <div class="block-section payment-method" v-show="selectedSaleType == 'instant-sale'">
              <h6>Payment Methods</h6>
              <div class="row d-flex align-items-center justify-content-center methods">
                <div class="col-md-6 col-lg-4 item">
                  <div :class="{
                    'default-cover': true,
                    selected: selectedPaymentMethod == 'CASH',
                  }" @click="selectPaymentMethod('CASH')">
                    <a href="javascript:void(0);">
                      <!-- <vue-feather type="bill"></vue-feather> -->
                      <i class="fa-solid fa-money-bill"></i>
                      <span>Cash</span>
                    </a>
                  </div>
                </div>
                <div class="col-md-6 col-lg-4 item">
                  <div :class="{
                    'default-cover': true,
                    selected: selectedPaymentMethod == 'CARD',
                  }" @click="selectPaymentMethod('CARD')">
                    <a href="javascript:void(0);">
                      <vue-feather type="credit-card"></vue-feather>
                      <span>Card</span>
                    </a>
                  </div>
                </div>
                <div class="col-md-6 col-lg-4 item">
                  <div :class="{
                    'default-cover': true,
                    selected: selectedPaymentMethod == 'MOBILE MONEY',
                  }" @click="selectPaymentMethod('MOBILE MONEY')">
                    <a href="javascript:void(0);">
                      <vue-feather type="smartphone"></vue-feather>
                      <!-- <img src="@/assets/img/icons/qr-scan.svg" alt="Payment Method" /> -->
                      <span>Mobile Money</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="block-section" v-show="selectedSaleType == 'credit-sale'">
              <h6>Invoice Details</h6>
              <div class="input-block">
                <label>Due Date</label>
                <input type="date" id="invoice-due-date" v-model="invoiceDueDate" class="form-control">
              </div>
              <div class="input-block mt-3">
                <label>Initial Payment Amount</label>
                <input type="number" :disabled="this.sale_items.length == 0" min=0
                  v-model.number="invoiceInitialPayment" class="form-control">
              </div>
            </div>

            <div v-if="selectedSaleType == 'instant-sale'" class="d-grid btn-block">
              <a href="javascript:void(0);" @click="populateReceipt"
                class="btn btn-success btn-icon flex-fill w-100 py-1"><span
                  class="me-1 d-flex align-items-center"><vue-feather type="check-circle"
                    class="feather-16 me-1"></vue-feather></span>Continue</a>
              <a class="btn btn-secondary" ref="openReceiptModalBtn" hidden href="javascript:void(0);"
                data-bs-toggle="modal" data-bs-target=".print-receipt-invoice">
                Open Print Receipt Modal
              </a>
            </div>

            <div v-else class="d-grid btn-block">
              <a href="javascript:void(0);" @click="populateInvoice"
                class="btn btn-success btn-icon flex-fill w-100 py-1"><span
                  class="me-1 d-flex align-items-center"><vue-feather type="check-circle"
                    class="feather-16 me-1"></vue-feather></span>Continue</a>
              <a class="btn btn-secondary" ref="openInvoiceModalBtn" hidden href="javascript:void(0);"
                data-bs-toggle="modal" data-bs-target="#print-invoice">
                Open Invoice Modal
              </a>
            </div>
            <!-- <div class="btn-row d-sm-flex align-items-center justify-content-between">
              <a href="javascript:void(0);" class="btn btn-info btn-icon flex-fill" data-bs-toggle="modal"
                data-bs-target="#hold-order"><span class="me-1 d-flex align-items-center"><vue-feather type="pause"
                    class="feather-16 me-1"></vue-feather></span>Hold</a>
              <a href="javascript:void(0);" class="btn btn-danger btn-icon flex-fill"><span
                  class="me-1 d-flex align-items-center"><vue-feather type="trash-2"
                    class="feather-16 me-1"></vue-feather></span>Void</a>
              <a href="javascript:void(0);" class="btn btn-success btn-icon flex-fill" data-bs-toggle="modal"
                data-bs-target="#payment-completed"><span class="me-1 d-flex align-items-center"><vue-feather
                    type="credit-card" class="feather-16 me-1"></vue-feather></span>Payment</a>
            </div> -->
          </aside>
        </div>
      </div>
    </div>
  </div>

  <pos-modal @new-customer="handleNewCustomer"></pos-modal>

  <print-receipt-modal :saleData="saleData" @sale-created="handleSaleCreated"></print-receipt-modal>
  <print-invoice-modal :saleData="saleData" @sale-created="handleSaleCreated"></print-invoice-modal>

</template>

<style scoped>
button:disabled {
  background-color: grey;
  color: white;
}

button:disabled:hover {
  cursor: not-allowed;
}

aside.product-order-list .payment-method .methods .default-cover.selected {
  border: 1px solid var(--color-primary);
}

aside.product-order-list .payment-method .methods .default-cover.selected a {
  color: var(--color-primary);
}

.sale-type .checkboxs {
  padding-left: 25px;
  margin-bottom: 16px;
}
</style>

<script>
import { Carousel, Navigation, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import POS from "@/assets/json/pos.json";
import Swal from "sweetalert2";

import { fetchStoreProducts } from "@/api/products";
import { selectedStoreState } from "@/state/storeState";
import { fetchCustomers } from "@/api/customers";
import { authState } from "@/state/authState";

export default {
  data() {
    return {
      invoiceInitialPayment: 0,
      invoiceDueDate: null,
      selectedSaleType: "instant-sale",
      selectedPaymentMethod: "",
      saleId: null,
      discountValue: 0,
      selectedCustomer: "",
      searchInput: "",
      POS: POS,
      data: [],
      filteredProducts: [],
      quantityToAdd: "",
      sale_items: [],
      customerChoices: ["Walk in Customer"],
      discountTypeChoices: ["Percentage", "Cash"],
      discountType: "Cash",
      customerData: [],
      saleData: {},
      loading: false,
      575: {
        itemsToShow: 3,
        snapAlign: "center",
      },
      767: {
        itemsToShow: 3,
        snapAlign: "center",
      },
      991: {
        itemsToShow: 4,
        snapAlign: "center",
      },
      1024: {
        itemsToShow: 5,
        snapAlign: "start",
      },
    };
  },
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  created() {
    // this.checkUserRole();
    this.fetchProducts(selectedStoreState.id);
    this.loadCustomers();
    this.selectedCustomer = this.customerChoices[0];
  },
  mounted() {
    var now = new Date(),
      // minimum date the user can choose, in this case now and in the future
      minDate = now.toISOString().substring(0, 10);

    document.querySelector('#invoice-due-date').setAttribute('min', minDate);

  },
  methods: {
    checkUserRole() {
      // if authenticated user is not a staff, 
      // show a non-dismissable alert
      if (authState.user.role !== 'STAFF') {
        Swal.fire({
          title: 'Warning',
          text: 'You must login with a staff account to complete a sale',
          icon: 'warning',
          showConfirmButton: true,
        });
      }
    },
    selectPaymentMethod(value) {
      this.selectedPaymentMethod = value;
    },
    handleDiscountChange() {
      if (this.discountType == "Percentage") {
        // allow max value of 100
        this.discountValue = Math.min(this.discountValue, 100);
      }
    },
    generateNewSaleId(saleType) {
      // Helper function to pad single digits with leading zeros
      const pad = (number, length) => number.toString().padStart(length, "0");

      // Get the current date
      const now = new Date();

      const day = String(now.getDate()).padStart(2, "0"); // Get day and pad with leading zero if necessary
      const month = String(now.getMonth() + 1).padStart(2, "0"); // Get month and pad with leading zero (months are 0-indexed)
      const year = String(now.getFullYear()).slice(-2); // Get last two digits of the year

      // format date as DDMMYY
      const dateStr = `${day}${month}${year}`;

      // Generate a random 5-digit number
      const randomDigits = pad(Math.floor(Math.random() * 100000), 5);

      let saleTypePrefix;

      // Create sale ID
      if (saleType == 'instant-sale') {
        saleTypePrefix = 'ISL'  
      } else if (saleType == 'credit-sale'){
        saleTypePrefix = 'CSL'
      }

      this.saleId = `${saleTypePrefix}-${randomDigits}-${dateStr}`;
    },
    handleNewCustomer(customerId) {
      this.loadCustomers();
      let c = this.customerData.find((customer) => customer.id == customerId);

      Swal.fire({
        title: "New Customer Added Successfully",
        icon: "success",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });
    },
    loadCustomers() {
      // Fetch customers from API
      fetchCustomers(selectedStoreState.id)
        .then((data) => {
          this.customerData = data;
          this.customerChoices = [
            "Walk in Customer",
            ...data.map((customer) => customer.full_name),
          ];
        })
        .catch((error) => {
          Swal.fire({
            title: "Unable To Fetch Customers",
            text: error.response?.data?.message || error.message,
            icon: "error",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          });
        });
    },
    showConfirmation() {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          Swal.fire({
            icon: "success",
            title: "Deleted!",
            text: "Your file has been deleted.",
            confirmButtonClass: "btn btn-success",
          });
        }
      });
    },
    async fetchProducts(storeId) {
      try {
        this.loading = true;
        const products = await fetchStoreProducts(storeId);
        this.data = products;
        this.filteredProducts = [...this.data];
      } catch (error) {
        this.data = [];
        if (error.response.data.message == "token_not_valid") {
        }

        this.emptyText = "Error: " + error.response.data.message;
        Swal.fire({
          title: "Unable To Fetch Products",
          text: error.response.data.message,
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        });
      } finally {
        this.loading = false; // Hide loader after fetching data
      }
    },
    addQuantity(productId) {
      // Use querySelector to find the input field within the parent element
      const inputField = document.querySelector(`#quantity-input-${productId}`);
      let value = inputField.value == "" ? 0 : parseInt(inputField.value, 10);
      inputField.value = value + 1;
    },
    subtractQuantity(productId) {
      // Use querySelector to find the input field within the parent element
      const inputField = document.querySelector(`#quantity-input-${productId}`);
      let value = inputField.value == "" ? 0 : parseInt(inputField.value, 10);
      if (value > 1) {
        inputField.value = value - 1;
      }
    },
    markAsAddedToCart(productId) {
      // return true if the product id exists in sale items
      return this.sale_items.some((item) => item.product === productId);
    },
    addToCart(product) {
      // Use querySelector to find the input field within the parent element
      const inputField = document.querySelector(
        `#quantity-input-${product.id}`
      );

      if (!inputField.value || inputField.value <= 0) {
        Swal.fire({
          title: "Invalid Quantity",
          text: "Please enter a valid quantity.",
          icon: "warning",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        });
        return;
      }

      // Check if the product already exists in the sale_items array
      const existingItem = this.sale_items.find(
        (item) => item.product_name === product.product_name
      );

      if (existingItem) {
        // If the product already exists
        Swal.fire({
          title: "Already Exists",
          text: "This product already exists",
          icon: "warning",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        });
      } else {
        // If the product doesn't exist, add it to the sale_items array
        const saleItem = {
          product: product.id, // If you want to generate a new ID, use UUID generation here
          product_name: product.product_name,
          quantity: parseInt(inputField.value, 10),
          unit_price: parseFloat(product.selling_price),
          deleted_product_name: null,
          sale: this.sale_id,
          image: product.image,
        };

        this.sale_items.push(saleItem);
        // if this is the first item added to cart, generate new sale id
        if (this.sale_items.length === 1) {
          this.generateNewSaleId(this.selectedSaleType);
        }

        Swal.fire({
          title: "Added to POS",
          text: `${inputField.value} ${product.product_name} added to POS`,
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        });
      }

    },
    incrementQuantity(productId) {
      // increment product quantity in sale items
      const saleItem = this.sale_items.find(
        (item) => item.product === productId
      );
      if (saleItem) {
        saleItem.quantity++;
      } else {
        Swal.fire({
          title: "Not Found",
          text: "Product not found in cart",
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },
    decrementQuantity(productId) {
      // decrement product quantity in sale items
      const saleItem = this.sale_items.find(
        (item) => item.product === productId
      );
      if (saleItem && saleItem.quantity > 1) {
        saleItem.quantity--;
      } else {
        Swal.fire({
          title: "Quantity Limit",
          text: "Cannot decrement the quantity below 1",
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },
    setQuantity(event, productId) {
      // set product quantity in sale items
      const saleItem = this.sale_items.find(
        (item) => item.product === productId
      );
      if (saleItem) {
        if (event.target.value && event.target.value > 0) {
          saleItem.quantity = parseInt(event.target.value, 10);
        } else {
          Swal.fire({
            title: "Invalid Quantity",
            text: "Please enter a valid quantity.",
            icon: "warning",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          });
        }
      } else {
        Swal.fire({
          title: "Not Found",
          text: "Product not found in cart",
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },
    removeFromCart(productId) {
      const saleItemIndex = this.sale_items.findIndex(
        (item) => item.product === productId
      );
      if (saleItemIndex > -1) {
        const removedProduct = this.sale_items.splice(saleItemIndex, 1);

        Swal.fire({
          title: `${removedProduct.product_name} removed from cart`,
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        });
      } else {
        Swal.fire({
          title: "Not Found",
          text: "Product not found in cart",
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },
    clearSaleItems() {
      if (this.sale_items.length > 0) {
        this.sale_items = [];

        Swal.fire({
          title: "Cart Cleared",
          text: "All items removed from cart",
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },
    handleSaleCreated() {
      this.resetSale();
      this.fetchProducts(selectedStoreState.id);
    },
    resetSale() {
      this.sale_items = [];
      this.selectedCustomer = this.customerChoices[0];
      this.saleId = null;
      this.selectedPaymentMethod = "";
      this.discountValue = 0;
      this.invoiceDueDate = null;
      this.invoiceInitialPayment = 0;

      Swal.fire({
        title: "Sale Reset",
        text: "Sale reset and cart cleared",
        icon: "success",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });

      // write the logic to clear/reset all inputs
      
    },
    getCustomerId(selectedCustomer) {
      const customer = this.customerData.find(
        (cust) => cust.full_name === selectedCustomer
      );

      if (customer) {
        return customer.id;
      } else {
        console.warn("Customer not found");
        return null; // or handle it according to your needs
      }
    },
    populateReceipt() {
      // This function is going to populate the data sent to the receipt

      // if (authState.user.role !== 'STAFF') {
      //   Swal.fire({
      //     title: 'Warning',
      //     text: 'You must login with a staff account to complete a sale',
      //     icon: 'warning',
      //     showConfirmButton: true,
      //   });
      //   return;
      // }

      // validate data before sending
      if (this.sale_items.length == 0) {
        Swal.fire({
          title: "No Items",
          text: "No items in the cart",
          icon: "warning",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        });
        return;
      }

      if (this.selectedPaymentMethod == "") {
        Swal.fire({
          title: "Select Payment Method",
          text: "Please select a payment method",
          icon: "warning",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        });
        return;
      }

      this.saleData = {
        customer_profile: this.getCustomerId(this.selectedCustomer),
        customer_name: this.selectedCustomer,
        sale_items: this.sale_items,
        reference_id: this.saleId,
        discount_value: this.discountValue,
        discount_type: this.discountType.toUpperCase(),
        calculated_discount: this.calculatedDiscount,
        sub_total: this.subTotal,
        total_bill: this.totalBill,
        payment_method: this.selectedPaymentMethod.toUpperCase(),
      };
      // console.log(this.saleData);

      // click on html button to open receipt modal
      this.$refs.openReceiptModalBtn.click();
    },
    populateInvoice() {
      // This function is going to populate the data sent to the invoice

      // if (authState.user.role !== 'STAFF') {
      //   Swal.fire({
      //     title: 'Warning',
      //     text: 'You must login with a staff account to complete a sale',
      //     icon: 'warning',
      //     showConfirmButton: true,
      //   });
      //   return;
      // }

      // if customer is Walk In Customer, give an alert that that's not 
      // allowed for credit sales
      if (this.selectedCustomer.toLowerCase() === 'walk in customer') {
        Swal.fire({
          title: "Invalid Customer",
          text: "Walk-In Customer is not allowed for credit sales",
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        });
        return;
      }

      // validate data before sending
      if (this.sale_items.length == 0) {
        Swal.fire({
          title: "No Items",
          text: "No items in the cart",
          icon: "warning",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        });
        return;
      }

      console.log(this.invoiceDueDate)
      if (this.invoiceDueDate == null) {
        Swal.fire({
          title: "Select Due Date Method",
          text: "Please set a due date for the invoice",
          icon: "warning",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        });
        return;
      }

      this.saleData = {
        customer_profile: this.getCustomerId(this.selectedCustomer),
        customer_name: this.selectedCustomer,
        sale_items: this.sale_items,
        reference_id: this.saleId,
        discount_value: this.discountValue,
        discount_type: this.discountType.toUpperCase(),
        calculated_discount: this.calculatedDiscount,
        sub_total: this.subTotal,
        total_bill: this.totalBill,
        payment_due_date: this.invoiceDueDate,
        payment_method: "CREDIT",
        status: this.invoiceInitialPayment > 0 ? "PARTIALLY_PAID" : "PENDING",
        total_paid: this.invoiceInitialPayment,
      };

      // console.log(this.saleData);

      // click on html button to open invoice modal
      this.$refs.openInvoiceModalBtn.click();
    },
  },
  watch: {
    searchInput(searchTerm) {
      // Filter the products based on the search term
      this.filteredProducts = this.data.filter((product) =>
        product.product_name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    },
    saleItems() {
      // if the saleItems is empty, set the saleId to null
      if (this.sale_items.length === 0) {
        this.sale_id = null;
      }
    },
    discountValue(value) {
      if (value == "") {
        this.discountValue = 0;
      }

      if (this.discountType === "Percentage" && value > 100) {
        this.discountValue = 100;

        Swal.fire({
          title: "Invalid Discount",
          text: "Discount cannot be more than 100% if discount type is percentage",
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        });
      } else {
        // If it's cash, the discount can't exceed the subTotal
        if (this.discountType === "Cash" && value > this.subTotal) {
          this.discountValue = this.subTotal;

          Swal.fire({
            title: "Invalid Discount",
            text: "Discount cannot exceed subtotal",
            icon: "error",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          });
        }
      }

      if (value < 0) {
        this.discountValue = 0;
      }
    },
    discountType(value) {
      if (value === "Percentage" && this.discountValue > 100) {
        this.discountValue = 100;
      }
    },
    invoiceInitialPayment(value) {
      if (value == "" || value < 0) {
        this.invoiceInitialPayment = 0;
      }
      else if (value >= this.subTotal - this.calculatedDiscount) {
        // invoice initial payment value cannot be greater than or equal to 
        // the subtotal minus the discount
        // if that happens, give an alert
        this.invoiceInitialPayment = this.subTotal - this.calculatedDiscount - 1;
        Swal.fire({
          title: "Invalid Initial Payment",
          text: "Initial payment cannot be greater than or equal to the subtotal minus discount",
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        });
      }

    },
    invoiceDueDate(value) {
      // Convert the value string to a Date object
      const dueDate = new Date(value);

      // Get the current date and set the time to 00:00:00 to compare only the date part
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      console.log(value)

      // Don't allow dates that occur before the current date
      if (value && dueDate < today) {
        this.invoiceDueDate = today.toISOString().substring(0,10);
        Swal.fire({
          title: "Invalid Due Date",
          text: "Due date cannot be in the past",
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },
    selectedSaleType(value) {
      this.generateNewSaleId(value)
    }

  },
  computed: {
    subTotal() {
      // Calculate the subtotal by summing up the total for each item
      return this.sale_items.reduce((total, item) => {
        return total + item.unit_price * item.quantity;
      }, 0);
    },
    totalBill() {
      // Calculate the total bill based on the sale type
      if (this.selectedSaleType == 'instant-sale') {
        return this.subTotal - this.calculatedDiscount;
      } else {
        return this.subTotal - this.calculatedDiscount - this.invoiceInitialPayment;
      }
    },
    calculatedDiscount() {
      // Calculate the discount based on the discount type
      if (this.discountType == "Percentage") {
        return this.subTotal * (this.discountValue / 100);
      }
      return this.discountValue;
    },
  },
};
</script>
