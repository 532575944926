import axiosFormDataInstance from "./axiosFormDataInstance";
import axiosJSONInstance from "./axiosJSONInstance";

// api call to create a new product in the store
export const createStoreProduct = async (storeId, data) => {
  try {
    const response = await axiosFormDataInstance.post(
      `/api/v1/stores/${storeId}/products/`,
      data
    );

    return response.data.data;
  } catch (error) {
    console.error("Error creating product", error);
    throw error;
  }
};

// api call to edit product in the store
export const updateStoreProduct = async (storeId, productId, data) => {
  try {
    const response = await axiosFormDataInstance.put(
      `/api/v1/stores/${storeId}/products/${productId}/`,
      data
    );

    return response.data.data;
  } catch (error) {
    console.error("Error updating product", error);
    throw error;
  }
};

export const fetchStoreProducts = async (storeId) => {
  try {
    const response = await axiosJSONInstance.get(
      `/api/v1/stores/${storeId}/products/`
    );

    return response.data.data;
  } catch (error) {
    console.error("Error fetching store products", error);
    throw error;
  }
};

export const fetchStoreProductDetails = async (storeId, productId) => {
  try {
    const response = await axiosJSONInstance.get(
      `/api/v1/stores/${storeId}/products/${productId}/`
    );

    return response.data.data;
  } catch (error) {
    console.error("Error fetching store product details", error);
    throw error;
  }
};

export const deleteProduct = async (storeId, productId) => {
  try {
    const response = await axiosJSONInstance.delete(
      `/api/v1/stores/${storeId}/products/${productId}/`
    );

    return response.data.data;
  } catch (error) {
    console.error("Error deleting product", error);
    throw error;
  }
};

// get low stock products
export const fetchLowStockProducts = async (storeId) => {
  try {
    const response = await axiosJSONInstance.get(
      `/api/v1/stores/${storeId}/products/low-stock/`
    );

    return response.data.data;
  } catch (error) {
    console.error("Error fetching low stock products", error);
    throw error;
  }
};

// get out of stock products
export const fetchOutOfStockProducts = async (storeId) => {
  try {
    const response = await axiosJSONInstance.get(
      `/api/v1/stores/${storeId}/products/out-of-stock/`
    );

    return response.data.data;
  } catch (error) {
    console.error("Error fetching out of stock products", error);
    throw error;
  }
};