<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>

  <div class="page-wrapper sales-list">
    <div class="content">
      <div class="page-header">
        <div class="add-item d-flex">
          <div class="page-title">
            <h4>Credit Sales</h4>
            <h6>Manage sales that were made on credit</h6>
          </div>
        </div>
        <ul class="table-top-head">
          <!-- <li>
            <a data-bs-toggle="tooltip" data-bs-placement="top" title="Pdf"
              ><img src="@/assets/img/icons/pdf.svg" alt="img"
            /></a>
          </li> -->
          <!-- <li>
            <a data-bs-toggle="tooltip" data-bs-placement="top" title="Excel"
              ><img src="@/assets/img/icons/excel.svg" alt="img"
            /></a>
          </li> -->
          <!-- <li>
            <a data-bs-toggle="tooltip" data-bs-placement="top" title="Print"
              ><vue-feather type="printer" class="printer"></vue-feather
            ></a>
          </li> -->
          <li>
            <a data-bs-toggle="tooltip" data-bs-placement="top" title="Refresh"><vue-feather type="rotate-ccw"
                class="rotate-ccw"></vue-feather></a>
          </li>
          <li>
            <a ref="collapseHeader" data-bs-toggle="tooltip" data-bs-placement="top" title="Collapse"
              @click="toggleCollapse">
              <i data-feather="chevron-up" class="feather-chevron-up"></i>
            </a>
          </li>
        </ul>
        <!-- <div class="page-btn">
          <a
            href="javascript:void(0);"
            class="btn btn-added"
            data-bs-toggle="modal"
            data-bs-target="#add-sales-new"
            ><vue-feather type="plus-circle" class="me-2"></vue-feather> Add New
            Sales</a
          >
        </div> -->
      </div>

      <!-- /product list -->
      <div class="card table-list-card">
        <div class="card-body">
          <div class="table-top">
            <div class="search-set">
              <div class="search-input">
                <input type="text" placeholder="Search using the sale reference" v-model="searchInput"
                  class="dark-input" />
                <a href="" class="btn btn-searchset"><i data-feather="search" class="feather-search"></i></a>
              </div>
            </div>
            <!-- <div class="search-path">
              <div class="d-flex align-items-center">
                <a
                  class="btn btn-filter"
                  id="filter_search"
                  v-on:click="filter = !filter"
                  :class="{ setclose: filter }"
                >
                  <vue-feather type="filter" class="filter-icon"></vue-feather>
                  <span
                    ><img src="@/assets/img/icons/closes.svg" alt="img"
                  /></span>
                </a>
              </div>
            </div> -->
            <!-- <div class="form-sort">
              <vue-feather type="sliders" class="info-img"></vue-feather>
              <vue-select
                :options="Sortdate"
                id="sortdate"
                placeholder="Sort by Date"
              />
            </div> -->
          </div>
          <!-- /Filter -->
          <div class="card" id="filter_inputs" :style="{ display: filter ? 'block' : 'none' }">
            <div class="card-body pb-0">
              <div class="row">
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="input-blocks">
                    <i data-feather="user" class="info-img"></i>
                    <vue-select :options="Macbook" id="macbook" placeholder="Choose Customer Name" />
                  </div>
                </div>
                <div class="col-lg-2 col-sm-6 col-12">
                  <div class="input-blocks">
                    <i data-feather="stop-circle" class="info-img"></i>
                    <vue-select :options="Fruits" id="fruits" placeholder="Choose Status" />
                  </div>
                </div>
                <div class="col-lg-2 col-sm-6 col-12">
                  <div class="input-blocks">
                    <i data-feather="file-text" class="info-img"></i>
                    <input type="text" placeholder="Enter Reference" class="form-control" />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="input-blocks">
                    <i data-feather="stop-circle" class="info-img"></i>
                    <vue-select :options="Computers" id="computer" placeholder="Choose Payment Status" />
                  </div>
                </div>
                <div class="col-lg-2 col-sm-6 col-12">
                  <div class="input-blocks">
                    <a class="btn btn-filters ms-auto">
                      <i data-feather="search" class="feather-search"></i>
                      Search
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Filter -->
          <div class="table-responsive">
            <div class="spinner-container d-flex justify-content-center align-items-center" v-if="loading">
              <div class="spinner-grow text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>

            <a-table v-else :columns="columns" :data-source="filteredSales">
              <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'Status'">
                  <div>
                    <span :class="record.Status_class">{{
                      record.Status
                    }}</span>
                  </div>
                </template>
                <template v-else-if="column.key === 'Payment_Status'">
                  <div>
                    <span :class="record.Payment_Class">{{
                      record.Payment_Status
                    }}</span>
                  </div>
                </template>
                <template v-else-if="column.key === 'action'">
                  <div class="text-center">
                    <div class="d-flex gap-2">
                      <a href="javascript:void(0);" class="dropdown-item detail-button" data-bs-toggle="modal"
                        data-bs-target="#sales-detail-new" @click="fetchDetail(record.id)"><i data-feather="eye"
                          class="feather-eye"></i></a>

                      <a href="javascript:void(0);" class="dropdown-item detail-button" data-bs-toggle="modal"
                        data-bs-target="#credit-sale-payment" @click="fetchDetail(record.id)"><i data-feather="plus"
                          class="feather-plus"></i></a>
                    </div>

                    <ul class="dropdown-menu sales-list-icon">
                      <li>
                        <a href="javascript:void(0);" class="dropdown-item" data-bs-toggle="modal"
                          data-bs-target="#sales-detail-new" @click="fetchDetail(record.id)"><vue-feather type="eye"
                            class="info-img"></vue-feather>Sale Detail</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" class="dropdown-item" data-bs-toggle="modal"
                          data-bs-target="#edit-sales-new"><vue-feather type="edit" class="info-img"></vue-feather>Edit
                          Sale</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" class="dropdown-item" data-bs-toggle="modal"
                          data-bs-target="#showpayment"><vue-feather type="dollar-sign"
                            class="info-img"></vue-feather>Show Payments</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" class="dropdown-item" data-bs-toggle="modal"
                          data-bs-target="#createpayment"><vue-feather type="plus-circle"
                            class="info-img"></vue-feather>Create Payment</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" class="dropdown-item"><vue-feather type="download"
                            class="info-img"></vue-feather>Download pdf</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" class="dropdown-item confirm-text mb-0"><vue-feather
                            type="trash-2" class="info-img"></vue-feather>Delete Sale</a>
                      </li>
                    </ul>
                  </div>
                </template>
              </template>
            </a-table>
          </div>
        </div>
      </div>
      <!-- /product list -->
    </div>
  </div>

  <sales-list-modal :selectedSaleId="selectedSalesItem"></sales-list-modal>
  <credit-sale-detail-modal :selectedSaleId="selectedSalesItem" :saleDetail="saleDetail" />
  <credit-sale-payment-modal :saleDetail="saleDetail" @credit-payment-success="handleCreditPaymentSuccess" />


</template>

<script>
import Swal from "sweetalert2";
import { fetchStoreSales } from "@/api/fetchStoreSales";
import { fetchSaleDetails } from "@/api/fetchSaleDetail";
import { selectedStoreState } from "@/state/storeState";
import { formatTime } from "@/assets/js/utils";

const columns = [
  {
    title: "Reference",
    dataIndex: "reference_id",
    sorter: {
      compare: (a, b) => {
        return a.reference_id.toLowerCase() > b.reference_id.toLowerCase() ? 1 : -1;
      },
    },
  },
  {
    title: "Customer",
    dataIndex: ["customer_profile", "fullName"],
    sorter: {
      compare: (a, b) => {
        const nameA = a.customer_profile?.fullName?.toLowerCase() || "";
        const nameB = b.customer_profile?.fullName?.toLowerCase() || "";
        return nameA > nameB ? 1 : nameA < nameB ? -1 : 0;
      },
    },
  },
  // {
  //   title: "Sale Date",
  //   dataIndex: "created_at",
  //   sorter: {
  //     compare: (a, b) => {
  //       const dateA = new Date(a.created_at);
  //       const dateB = new Date(b.created_at);
  //       return dateA - dateB;
  //     },
  //   },
  // },
  {
    title: "Remaining Due",
    dataIndex: "remaining_balance",
    sorter: {
      compare: (a, b) => a.remaining_balance - b.remaining_balance,
    },
  },
  {
    title: "Due Date",
    dataIndex: "payment_due_date",
    sorter: {
      compare: (a, b) => {
        const dueDateA = new Date(a.payment_due_date);
        const dueDateB = new Date(b.payment_due_date);
        return dueDateA - dueDateB;
      },
    },
  },
  // {
  //   title: "Grand Total",
  //   dataIndex: "total_bill",
  //   sorter: {
  //     compare: (a, b) => a.total_bill - b.total_bill,
  //   },
  //   customRender: ({ record }) => {
  //     const totalBill = parseFloat(record.total_bill).toFixed(2);
  //     return totalBill; // Display with 2 decimal places
  //   },
  // },
  {
    title: "Salesperson",
    dataIndex: ["salesperson", "full_name"],
    sorter: {
      compare: (a, b) => {
        const nameA = a.salesperson?.full_name?.toLowerCase() || "";
        const nameB = b.salesperson?.full_name?.toLowerCase() || "";
        return nameA > nameB ? 1 : nameA < nameB ? -1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "action",
    sorter: true,
  },
];



// get the data from the backend.

export default {
  data() {
    return {
      searchInput: "",
      filter: false,
      columns,
      filteredSales: [],
      data: [],
      saleDetail: {},
      selectedSalesItem: "",
      loading: true,
    };
  },
  created() {
    this.fetchSales();
  },
  methods: {
    toggleCollapse() {
      const collapseHeader = this.$refs.collapseHeader;

      if (collapseHeader) {
        collapseHeader.classList.toggle("active");
        document.body.classList.toggle("header-collapse");
      }
    },
    async fetchDetail(saleId) {
      // this.loading = true; // Show loader before fetching data
      try {
        const saledetail = await fetchSaleDetails(
          selectedStoreState.id,
          saleId
        );
        this.saleDetail = saledetail;
      } catch (error) {
        // this.sales = [];
        this.emptyText = "Error: " + error.message;
        Swal.fire({
          title: "Unable To Fetch Products",
          text: error?.response?.data?.message || error.message,
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        });
      } finally {
        // this.loading = false; // Hide loader after fetching data
      }
    },
    async fetchSales() {
      try {
        const sales = await fetchStoreSales(selectedStoreState.id, 'credit');

        // preprocessing
        for (let sale of sales) {
          sale.created_at = formatTime(sale.created_at);
          sale.payment_due_date = formatTime(sale.payment_due_date);
          sale.customer_profile.fullName =
            sale.customer_profile?.first_name +
            " " +
            sale.customer_profile?.last_name;
        }

        this.sales = sales;
        this.filteredSales = [...this.sales]; // Reset the filtered sales to show all products initially
      } catch (error) {
        this.sales = [];
        this.emptyText = "Error: " + error.message;
        Swal.fire({
          title: "Unable To Fetch Products",
          text: error?.response?.data?.message || error.message,
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        });
      } finally {
        this.loading = false; // Hide loader after fetching data
      }
    },
    async handleCreditPaymentSuccess() {
      this.fetchSales(); // Fetch new sales data after successful credit payment
    },
  },
  watch: {
    searchInput(searchTerm) {
      // Filter the products based on the search term
      this.filteredSales = this.sales.filter((sale) =>
        sale.reference_id.toLowerCase().includes(searchTerm.toLowerCase())
      );
    },
  },
};
</script>

<style scoped>
a.detail-button {
  border: 1px solid rgba(145, 158, 171, 0.3);
  background-color: #ffffff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  padding: 8px;
}

a.detail-button:hover {
  background-color: #092c4c;
  border-color: #092c4c;
}
</style>
