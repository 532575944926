import axiosJSONInstance from "./axiosJSONInstance";

export const fetchStoreSales = async (storeId, paymentType) => {
  let url =  `/api/v1/stores/${storeId}/sales/`;
  if (['credit', 'instant'].includes(paymentType)) {
    url += `${paymentType.toLowerCase()}/`;
  }
  
  try {
    const response = await axiosJSONInstance.get(
      url
    );

    return response.data.data;
  } catch (error) {
    console.error("Error fetching sales ", error);
    throw error;
  }
};
