import axiosJSONInstance from "./axiosJSONInstance";


export const logIn = async data => {
    try {
        const response = await axiosJSONInstance.post(
            "/api/v1/auth/login/",
            data
        );
        
        if (response.status >= 200 && response.status < 300) {
            return response.data.data;
        } else {
            console.log("response got an error")
            throw new Error("Failed to login");
        }
    } catch (error) {
        console.error("Error submitting login credentials", error);
        throw error;
    }
};