<template>
  <div>
    <layout-header></layout-header>
    <layout-sidebar></layout-sidebar>

    <div class="page-wrapper">
      <div v-if="loading" class="mt-5 h-100 d-flex align-items-center justify-content-center">
        <div class="spinner-container d-flex justify-content-center align-items-center" v-if="loading">
          <div class="spinner-grow text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
      <div v-else-if="data == {}" class="mt-5 h-100 d-flex align-items-center justify-content-center">
        <div class="spinner-container d-flex justify-content-center align-items-center" v-if="loading">
          <div class="spinner-grow text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
      <div v-else class="content">
        <div class="row">
          <div class="col-xl-3 col-sm-6 col-12 d-flex">
            <div class="dash-widget w-100">
              <div class="dash-widgetimg">
                <span><img src="@/assets/img/icons/dash1.svg" alt="img" /></span>
              </div>
              <div class="dash-widgetcontent">
                <h5 class="d-flex gap-1">
                  GH₵ <count-up class="counters" ref="counter" :startVal="0" :delay="3" :endVal="data?.totalSalesAmount"
                    :duration="5" :autoplay="true" />
                </h5>
                <h6>Completed Sales</h6>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-sm-6 col-12 d-flex">
            <div class="dash-widget dash1 w-100">
              <div class="dash-widgetimg">
                <span><img src="@/assets/img/icons/dash2.svg" alt="img" /></span>
              </div>
              <div class="dash-widgetcontent">
                <h5 class="d-flex gap-1">
                  GH₵ <count-up class="counters" ref="counter" :startVal="0" :delay="3" :endVal="data?.totalProfit"
                    :duration="5" :autoplay="true" />
                </h5>
                <h6>Profit on Completed Sales</h6>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-sm-6 col-12 d-flex">
            <div class="dash-widget dash2 w-100">
              <div class="dash-widgetimg">
                <span><img src="@/assets/img/icons/debt-repayment-svgrepo-com.svg" alt="img" /></span>
              </div>
              <div class="dash-widgetcontent">
                <h5 class="d-flex gap-1">
                  GH₵ <count-up class="counters" ref="counter" :startVal="0" :delay="3"
                    :endVal="data?.totalPaidCreditSales" :duration="5" :autoplay="true" />
                </h5>
                <h6>Amount Paid from Credit Sales</h6>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-sm-6 col-12 d-flex">
            <div class="dash-widget dash3 w-100">
              <div class="dash-widgetimg">
                <span><img src="@/assets/img/icons/expense.svg" alt="img" /></span>
              </div>
              <div class="dash-widgetcontent">
                <h5 class="d-flex gap-1">
                  GH₵ <count-up class="counters" ref="counter" :startVal="0" :delay="3" :endVal="data?.totalAmountOwed"
                    :duration="5" :autoplay="true" />
                </h5>
                <h6>Total Amount Owed from Credit Sales</h6>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-sm-6 col-12 d-flex">
            <div class="dash-count das3">
              <div class="dash-counts">
                <h4>{{ data?.salesCount }}</h4>
                <h5>Sales</h5>
              </div>
              <div class="d-none dash-imgs">
                <vue-feather type="file"></vue-feather>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-sm-6 col-12 d-flex">
            <div class="dash-count das1">
              <div class="dash-counts">
                <h4>{{ data?.productsCount }}</h4>
                <h5>Products</h5>
              </div>
              <div class="d-none dash-imgs">
                <vue-feather type="user"></vue-feather>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-sm-6 col-12 d-flex">
            <div class="dash-count">
              <div class="dash-counts">
                <h4>{{ data?.lowStockProductsCount }}</h4>
                <h5>Low Stock</h5>
              </div>
              <div class="d-none dash-imgs">
                <vue-feather type="user-check"></vue-feather>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-sm-6 col-12 d-flex">
            <div class="dash-count das2">
              <div class="dash-counts">
                <h4>{{ data?.outOfStockProductsCount }}</h4>
                <h5>Out of Stock</h5>
              </div>
              <div class="d-none dash-imgs">
                <!-- <vue-feather type=""></vue-feather> -->
                <img src="@/assets/img/icons/file-text-icon-01.svg" class="img-fluid" alt="icon" />
              </div>
            </div>
          </div>
        </div>
        <!-- Button trigger modal -->

        <div class="row">
          <div class="col-xl-7 col-sm-12 col-12 d-flex">
            <div class="card flex-fill">
              <div class="card-header d-flex justify-content-between align-items-center">
                <h5 class="card-title mb-0">Sales over Past 7 Days</h5>
                <div class="graph-sets">
                  <!-- <ul class="mb-0">
                    <li>
                      <span>Sales</span>
                    </li>
                    <li>
                      <span>Purchase</span>
                    </li>
                  </ul> -->
                  <!-- <div class="dropdown dropdown-wraper">
                    <button
                      class="btn btn-light btn-sm dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      2024
                    </button>
                    <ul
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <li>
                        <a href="javascript:void(0);" class="dropdown-item"
                          >2024</a
                        >
                      </li>
                      <li>
                        <a href="javascript:void(0);" class="dropdown-item"
                          >2023</a
                        >
                      </li>
                      <li>
                        <a href="javascript:void(0);" class="dropdown-item"
                          >2022</a
                        >
                      </li>
                    </ul>
                  </div> -->
                </div>
              </div>
              <div class="card-body p-0 d-flex flex-column justify-content-center">
                <div id="sales_charts"></div>
                <apexchart type="bar" height="300" :options="basicAreaChart.sline" :series="basicAreaChart.series">
                </apexchart>
              </div>
            </div>
          </div>

          <div class="col-xl-5 col-sm-12 col-12 d-flex">
            <div class="card flex-fill default-cover mb-4">
              <div class="card-header d-flex justify-content-between align-items-center">
                <h4 class="card-title mb-0">Top Selling Products</h4>
                <!-- <div class="view-all-link">
                  <a
                    href="javascript:void(0);"
                    class="view-all d-flex align-items-center"
                  >
                    View All<span class="ps-2 d-flex align-items-center"
                      ><vue-feather
                        type="arrow-right"
                        class="feather-16"
                      ></vue-feather
                    ></span>
                  </a>
                </div> -->
              </div>
              <div class="card-body">
                <div class="table-responsive dataview ranked-products">
                  <table class="table dashboard-recent-products">
                    <thead>
                      <tr>
                        <!-- <th>Rank</th> -->
                        <th>Product</th>
                        <th>Purchase Count</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(product, index) in data?.rankedProducts" :key="index">
                        <!-- <td>{{ index + 1 }}</td> -->
                        <td class="productimgname">
                          <router-link :to="`/inventory/product-list`" class="product-img">
                            <img :src="product.image ||
                              require(`@/assets/img/products/default-product-image.png`)
                              " :alt="product.product_name" />
                          </router-link>
                          <router-link :to="`/inventory/product-list`">{{
                            product.product_name
                          }}</router-link>
                        </td>
                        <td>{{ product.sales_quantity || 0 }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-6 col-sm-12 col-12 d-flex">
            <div class="card flex-fill">
              <div class="card-header">
                <h4 class="card-title">Low Stock Products</h4>
              </div>
              <div class="card-body">
                <div class="table-responsive dataview">
                  <table class="table dashboard-expired-products">
                    <thead>
                      <tr>
                        <!-- <th class="no-sort">
                          <label class="checkboxs">
                            <input type="checkbox" id="select-all" />
                            <span class="checkmarks"></span>
                          </label>
                        </th> -->
                        <th>Product</th>
                        <th>Reference ID</th>
                        <th>In Stock</th>
                        <!-- <th>Minimum Quantity</th> -->
                        <!-- <th class="no-sort">Action</th> -->
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="product in data?.lowStockProducts" :key="product.id">
                        <!-- <td>
                          <label class="checkboxs">
                            <input type="checkbox" />
                            <span class="checkmarks"></span>
                          </label>
                        </td> -->
                        <td>
                          <div class="productimgname">
                            <a href="javascript:void(0);" class="product-img stock-img">
                              <img :src="product.image ||
                                require(`@/assets/img/products/default-product-image.png`)
                                " :alt="product.product_name" />
                            </a>
                            <a href="javascript:void(0);">{{ product.product_name }} </a>
                          </div>
                        </td>
                        <td>
                          <a href="javascript:void(0);">{{ product.reference_id }}</a>
                        </td>
                        <td>{{ product.quantity_in_stock }}</td>
                        <!-- <td>{{ product.minimum_alert_quantity }}</td> -->
                        <!-- <td class="action-table-data">
                          <div class="edit-delete-action">
                            <a class="me-2 p-2" href="javascript:void(0);">
                              <vue-feather
                                type="edit"
                                class="feather-edit"
                              ></vue-feather>
                            </a>
                            <a
                              class="confirm-text p-2"
                              @click="showConfirmation"
                              href="javascript:void(0);"
                            >
                              <vue-feather
                                type="trash-2"
                                class="feather-trash-2"
                              ></vue-feather>
                            </a>
                          </div>
                        </td> -->
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-sm-12 col-12 d-flex">
            <div class="card flex-fill">
              <div class="card-header">
                <h4 class="card-title">Out Of Stock Products</h4>
              </div>
              <div class="card-body">
                <div class="table-responsive dataview">
                  <table class="table dashboard-expired-products">
                    <thead>
                      <tr>
                        <!-- <th class="no-sort">
                          <label class="checkboxs">
                            <input type="checkbox" id="select-all" />
                            <span class="checkmarks"></span>
                          </label>
                        </th> -->
                        <th>Product</th>
                        <th>Reference ID</th>
                        <!-- <th>In Stock</th> -->
                        <!-- <th>Minimum Quantity</th> -->
                        <!-- <th class="no-sort">Action</th> -->
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="product in data?.outOfStockProducts" :key="product.id">
                        <!-- <td>
                          <label class="checkboxs">
                            <input type="checkbox" />
                            <span class="checkmarks"></span>
                          </label>
                        </td> -->
                        <td>
                          <div class="productimgname">
                            <a href="javascript:void(0);" class="product-img stock-img">
                              <img :src="product.image ||
                                require(`@/assets/img/products/default-product-image.png`)
                                " :alt="product.product_name" />
                            </a>
                            <a href="javascript:void(0);">{{ product.product_name }} </a>
                          </div>
                        </td>
                        <td>
                          <a href="javascript:void(0);">{{ product.reference_id }}</a>
                        </td>
                        <!-- <td>{{ product.quantity_in_stock }}</td> -->
                        <!-- <td>{{ product.minimum_alert_quantity }}</td> -->
                        <!-- <td class="action-table-data">
                          <div class="edit-delete-action">
                            <a class="me-2 p-2" href="javascript:void(0);">
                              <vue-feather
                                type="edit"
                                class="feather-edit"
                              ></vue-feather>
                            </a>
                            <a
                              class="confirm-text p-2"
                              @click="showConfirmation"
                              href="javascript:void(0);"
                            >
                              <vue-feather
                                type="trash-2"
                                class="feather-trash-2"
                              ></vue-feather>
                            </a>
                          </div>
                        </td> -->
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { basicAreaChart } from "./data";
import AdminProducts from "@/assets/json/admin-products.json";
import AdminExpired from "@/assets/json/admin-expired.json";
import Swal from "sweetalert2";
import { fetchDashboardData } from "@/api/dashboard";
import { selectedStoreState } from "@/state/storeState";

export default {
  data() {
    return {
      basicAreaChart: basicAreaChart,
      AdminProducts: AdminProducts,
      AdminExpired: AdminExpired,
      loading: true,
      data: {
        totalSalesAmount: 0,
        totalProfit: 0,
        totalAmountOwed: 0,
        totalPaidCreditSales: 0,
        salesCount: 0,
        productsCount: 0,
        lowStockProductsCount: 0,
        outOfStockProductsCount: 0,
        rankedProducts: [],
        lowStockProducts: [],
        outOfStockProducts: [],
        salesChartData: {}
      },
    };
  },
  created() {
    this.getDashboardData(selectedStoreState.id);
  },
  methods: {
    async getDashboardData(storeId) {
      try {
        const data = await fetchDashboardData(storeId);
        this.data = {
          totalSalesAmount: data.total_sales,
          totalProfit: data.total_profit,
          totalAmountOwed: data.total_amount_owed,
          totalPaidCreditSales: data.total_paid_from_credit_sales,
          salesCount: data.sales_count,
          productsCount: data.products_count,
          lowStockProductsCount: data.low_stock_count,
          outOfStockProductsCount: data.out_of_stock_count,
          rankedProducts: data.top_10_products,
          lowStockProducts: data.low_stock_products,
          outOfStockProducts: data.out_of_stock_products,
          salesChartData: data.sales_over_last_7_days
        };
        // Set chart data
        basicAreaChart.sline.xaxis.categories = Object.getOwnPropertyNames(
          this.data?.salesChartData
        );
        basicAreaChart.series[0].data = Object.values(
          this.data?.salesChartData
        );
      } catch (error) {
        this.data = {};

        Swal.fire({
          title: "Unable To Fetch Dashboard Data",
          text: error?.response?.data?.message || error.message,
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        });
      } finally {
        this.loading = false; // Hide loader after fetching data
      }
    },
    showConfirmation() {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          Swal.fire({
            icon: "success",
            title: "Deleted!",
            text: "Your file has been deleted.",
            confirmButtonClass: "btn btn-success",
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.table-responsive.dataview.ranked-products {
  max-height: 360px;
  overflow-y: auto;
}

.table-responsive.dataview.ranked-products table {
  width: 100%;
  table-layout: fixed;
}
</style>